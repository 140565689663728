@import 'common.css';

/*** Autocomplete Dropdown (from JS plugin) ***/
div.autocomplete {
background: transparent;
z-index: auto;
font: inherit;
overflow: visible;
box-sizing: inherit;
border: 0px solid;
}
body > div.autocomplete {
margin: .5rem 0 0 0;
padding: .5rem 0;
border-radius: .25rem;
background-color: #ffffff;
box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
border: 1px solid #9ca3af;
max-height: 24rem !important;
overflow-y: auto;
}
body > div.autocomplete > div {
display: flex;
align-items: center;
padding: .5rem 1rem;
text-decoration: none;
font-size: 0.875rem;
line-height: 1.25rem;
background-color: #ffffff;
color: #374151;
cursor: pointer;
}
body > div.autocomplete > div:hover {
background-color: #f3f4f6 !important;
}

/*** Swiper ***/
.swiper-slide {
height: auto !important;
}
.swiper-slide figure.swiper-lazy {
background-size: 100% 100% !important;
}
.swiper-pagination {
padding-bottom: 1.25rem;
padding-top: 1.25rem;
bottom: 0 !important;
position: static !important;
}
.swiper-pagination-bullet {
width: .75rem !important;
height: .75rem !important;
}
.swiper-pagination-bullet-active {
background: #10b981 !important;
}
.custom-swiper-button {
position: absolute;
z-index: 20;
bottom: 0;
outline: none;
}
.custom-swiper-button-prev {
left: .5rem;
}
.custom-swiper-button-next {
right: .5rem;
}
.custom-swiper-button-prev, .custom-swiper-button-next {
width: 2.5rem !important;
height: 3.85rem !important;
border-radius: 0 !important;
cursor: pointer;
background-size: 2.5rem 2.5rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent !important;
}
.custom-swiper-button-prev {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%2310b981'%3E%3Cpath fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
.custom-swiper-button-next {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%2310b981'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
.custom-swiper-button-prev.swiper-button-disabled, .custom-swiper-button-next.swiper-button-disabled {
display: none !important;
}
@keyframes spinner {
from { transform: rotate(0deg); }
to { transform: rotate(360deg); }
}
.swiper-lazy-preloader {
animation: spinner .6s linear infinite;
}

/*** Typography ***/
.heading {
margin: 0;
color: rgb(79,70,229);
text-transform: uppercase;
letter-spacing: .025em;
font-size: 1rem;
line-height: 1.5rem;
/*font-weight: 600;*/
}
.title {
margin: .5rem 0 0;
color: rgb(17,24,39);
letter-spacing: -.025em;
font-size: 1.875rem;
line-height: 2.25rem;
/*font-weight: 700;*/
}
@media (min-width: 640px) {
.title {
font-size: 2.25rem;
line-height: 2.5rem;
}
}
.accented {
/*margin: 1rem 0 0;*/
color: rgb(107,114,128);
font-size: 1.25rem;
line-height: 1.75rem;
}
.note {
margin: 1.25rem 0 0;
padding: 0;
color: rgb(107,114,128);
font-size: .875rem;
line-height: 1.25rem;
}

/*** Square boxes ***/
ul.bars {
margin: 0 0 1.5rem;
padding: 0;
list-style: none;
list-style-type: none;
font-weight: 600;
border-top: 1px solid #e5e7eb;
display: flex;
flex-wrap: wrap;
justify-content: center;
}
ul.bars li {
display: block;
box-sizing: border-box;
list-style: none;
list-style-type: none;
border-right: 1px solid #e5e7eb;
border-bottom: 1px solid #e5e7eb;
}
ul.bars li a {
padding: 1.25rem;
display: block;
height: 100%;
text-align: center;
color: #1f2937;
}
ul.bars li a:hover {
color: #ffffff;
background-color: #4b5563;
}
ul.bars li {
flex: 0 0 calc(50%);
}
ul.bars li:nth-child(2n + 1) {
border-left: 1px solid #e5e7eb;
}
@media (min-width: 640px) {
ul.bars li {
flex: 0 0 calc(33.33%);
}
ul.bars li:nth-child(2n + 1) {
border-left: none;
}
ul.bars li:nth-child(3n + 1) {
border-left: 1px solid #e5e7eb;
}
}
@media (min-width: 768px) {
ul.bars li {
flex: 0 0 calc(25%);
}
ul.bars li:nth-child(2n + 1) {
border-left: none;
}
ul.bars li:nth-child(3n + 1) {
border-left: none;
}
ul.bars li:nth-child(4n + 1) {
border-left: 1px solid #e5e7eb;
}
}
@media (min-width: 1024px) {
ul.bars li {
flex: 0 0 calc(20%);
}
ul.bars li:nth-child(2n + 1) {
border-left: none;
}
ul.bars li:nth-child(3n + 1) {
border-left: none;
}
ul.bars li:nth-child(4n + 1) {
border-left: none;
}
ul.bars li:nth-child(5n + 1) {
border-left: 1px solid #e5e7eb;
}
}

/*** Auth ***/
body.auth #main {
margin: 0;
padding:  6rem 1rem 2.5rem;
background-size: cover;
background-repeat: no-repeat;
background-position: 50%;
}
body.auth #main form {
margin: 1.25rem auto 3rem;
padding: 1.5rem 2rem 2rem;
max-width: 32rem;
background-color: #ffffff;
border-radius: .25rem;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
body.auth #main .container > p {
text-align: center;
}
body.auth #main p a {
color: #047857;
font-weight: 600;
}
body.auth #main form .form-action {
margin-bottom: 0;
text-align: left;
}
body.auth #main form .form-action .button, body.auth #main form .form-action button {
margin-left: 0;
width: 100%;
}
body.auth #main form .form-group a {
color: #047857;
font-weight: 600;
}
body.auth #main form .button-group {
margin-top: 1rem;
align-items: center;
display: flex;
}
body.auth #main form .button-group .button, body.auth #main form .button-group button {
margin-left: 0.75rem;
flex: 1 1 0%;
}
body.auth #main form .button-group .button:first-child {
margin-left: 0;
}
body.auth #main form .button-group .button svg, body.auth #main form .button-group button svg {
display: inline-block;
width: 1.25rem;
height: 1.25rem;
margin: 0 auto;
}
body.auth #main #login-form .row {
padding-top: .5rem;
align-items: flex-start;
justify-content: flex-start;
display: flex;
}
body.auth #main #login-form .row > div {
flex-grow: 1;
}
body.auth #main #login-form .row > div:last-child {
margin-left: 0.75rem;
}
body.auth #main #login-form .row .checkbox {
padding: 0;
}
body.auth #main #verify-form p {
text-align: center;
}
body.auth #main #verify-form button {
margin: 0;
padding: 0;
border: 0;
box-shadow: none;
text-transform: none;
font-weight: 600;
display: inline;
background-color: transparent;
color: #047857;
width: auto;
}
body.auth #main #code-form .code {
display: grid;
grid-template-columns: repeat(4,minmax(0,1fr));
gap: 1rem;
}
body.auth #main #code-form .form-action {
margin-bottom: 1rem;
}
body.auth #main #code-form .form-action:last-child {
margin-bottom: 0;
}

/*** User Profile ***/
body.user #main {
margin: 3.5rem 0 2.5rem;
}
body.user #main .header .background {
margin: 0 -1rem;
height: 16rem;
background-color: #d1d5db;
background-position: center;
background-size: cover;
}
body.user #main .header .vcard {
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: flex-start;
min-height: 100px;
}
body.user #main .header .vcard .info {
padding: 0 1rem;
width: 100%;
justify-content: center;
position: relative;
}
body.user #main .header .vcard .info figure {
margin:  0 0 5rem;
padding: 0 1rem;
display: flex;
width: 100%;
justify-content: center;
position: relative;
}
body.user #main .header .vcard .info figure img {
margin: -4rem;
position: absolute;
border: 2px solid #ffffff;
border-radius: 9999px;
vertical-align: middle;
width: 100%;
max-width: 8rem;
height: auto;
max-height: 8rem;
background: #ffffff;
filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
@media (min-width: 1024px) {
body.user #main .header .vcard .info {
padding: 0;
margin: 0 !important;
width: 36%;
order: 2;
}
}
body.user #main .header .vcard .actions {
padding: 1rem 1rem;
width: 100%;
text-align: center;
justify-content: center;
}
@media (min-width: 1024px) {
body.user #main .header .vcard .actions {
margin: 0 !important;
padding: 1.5rem 0 0;
width: 32%;
order: 3;
text-align: right;
justify-content: right;
}
body.user #main .header .vcard .actions button {
margin-bottom: .5rem;
}
}
body.user #main .header .vcard .links {
padding: 1rem 1rem;
width: 100%;
justify-content: center;
text-align: center;
}
@media (min-width: 1024px) {
body.user #main .header .vcard .links {
margin: 0 !important;
padding: 1.5rem 0 0;
width: 32%;
order: 1;
justify-content: left;
text-align: left;
}
}
body.user #main .header .stats {
display: flex;
flex-direction: row;
align-items: center;
border-top: 1px solid #9ca3af;
border-left: 1px solid #9ca3af;
border-radius: .25rem;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4.21875px 7px -1.0625px, rgba(0, 0, 0, 0.055) 0px 2.0625px 3px -0.703125px;
width: 100%;
max-width: 32rem;
margin: 0 auto;
}
body.user #main .header .stats > div {
padding: .5rem;
flex: 1 1 0%;
border-right: 1px solid #9ca3af;
border-bottom: 1px solid #9ca3af;
text-align: center;
}
body.user #main .header .stats > div:first-child {
border-top-left-radius: .25rem;
border-bottom-left-radius: .25rem;
}
body.user #main .header .stats > div:last-child {
border-top-right-radius: .25rem;
border-bottom-right-radius: .25rem;
}
body.user #main .header .stats > div var, body.user #main .header .stats > div strong {
display: block;
font-weight: bold;
font-style: normal;
font-size: 1.25rem;
line-height: 1.75rem;
text-transform: uppercase;
color: #374151;
}
body.user #main .header .stats > div span {
display: block;
font-size: 0.875rem;
line-height: 1.25rem;
color: #6b7280;
}
body.user #main .header .meta {
text-align: center;
}
body.user #main .header .meta address {
margin: 1rem auto 0;
justify-content: center;
text-align: center;
}
body.user #main .header .meta .created {
margin-top: .5rem;
font-size: 0.875rem;
line-height: 1.25rem;
color: #6b7280;
}
body.user #main .tabs {
margin-top: 1rem;
}

/*** Content Layout ***/
#main .container > .wrapper > .content {
margin-top: 2rem;
position: relative;
}
#main .container > .wrapper > .sidebar {
margin-top: 2rem;
position: relative;
}
body.business #main .container > .wrapper > .content, body.club #main .container > .wrapper > .content, body.event #main .container > .wrapper > .content {
margin-top: 0;
}
body.business #main .container > .wrapper > .sidebar, body.club #main .container > .wrapper > .sidebar, body.event #main .container > .wrapper > .sidebar {
margin-top: 2rem;
}
#main .container > .wrapper > .content .wrapper > .sidebar {
margin-top: 2rem;
position: relative;
}
@media (min-width: 768px) {
#main .container > .wrapper {
grid-template-columns: repeat(10,minmax(0,1fr));
display: grid;
gap: 2rem;
}
#main .container > .wrapper > .sidebar {
margin-top: 0;
grid-column: span 2/span 2;
order: 1;
}
#main .container > .wrapper > .content {
margin-top: 0;
grid-column: span 8/span 8;
order: 2;
}
body.business #main .container > .wrapper > .content, body.club #main .container > .wrapper > .content, body.event #main .container > .wrapper > .content, body.community #main .container > .wrapper > .content {
grid-column: span 7/span 7;
order: 1;
}
body.business #main .container > .wrapper > .sidebar, body.club #main .container > .wrapper > .sidebar, body.event #main .container > .wrapper > .sidebar, body.community #main .container > .wrapper > .sidebar {
margin-top: 0;
grid-column: span 3/span 3;
order: 2;
}
}
@media (min-width: 1024px) {
#main .container > .wrapper > .content .wrapper {
grid-template-columns: repeat(8,minmax(0,1fr));
display: grid;
gap: 2rem;
}
#main .container > .wrapper > .content .wrapper > .content {
margin-top: 0;
grid-column: span 5/span 5;
}
#main .container > .wrapper > .content .wrapper > .sidebar {
margin-top: 0;
grid-column: span 3/span 3;
}
}

/*** Sidebar ***/
#sidebar h2 {
margin-bottom: 1.25rem;
text-transform: uppercase;
color: #4b5563;
font-size: 0.75rem;
line-height: 1rem;
font-weight: 700;
display: block;
}
#sidebar p {
margin: 0 0 1.5rem;
}
#sidebar ul {
margin: 0 0 2.5rem;
padding: 0;
list-style: none;
list-style-type: none;
}
#sidebar ul li, #sidebar ul li.level-0 {
margin: 0;
padding: .5rem 0;
list-style: none;
list-style-type: none;
font-weight: 600;
border-bottom: 1px dashed #e5e7eb;
}
#sidebar ul li.level-1 {
padding: .5rem 0 .5rem .75rem;
font-weight: 500;
}
#sidebar ul li.level-2 {
padding: .5rem 0 .5rem 1.5rem;
font-weight: 500;
}
#sidebar ul li.level-3 {
padding: .5rem 0 .5rem 2.25rem;
font-weight: 500;
}
#sidebar ul li.level-4 {
padding: .5rem 0 .5rem 3rem;
font-weight: 500;
}
#sidebar ul li strong {
font-weight: 600;
}
#sidebar ul li a {
color: #1f2937;
text-decoration: none;
display: block;
}
#sidebar ul li a:hover {
color: #4b5563;
}
#sidebar figure {
margin: 0 0 2.5rem;
padding: 0;
}
#sidebar figure div {
cursor: pointer;
}
#sidebar figure img {
width: 100%;
height: auto;
max-width: 100%;
}
#sidebar .properties {
margin: 0 0 2.5rem;
padding: 1rem;
background: #eeeeee;
border-top: 1px solid #9ca3af;
border-bottom: 1px solid #9ca3af;
}
#sidebar .properties p {
margin: 0 0 .5rem;
}
#sidebar .properties p:last-child {
margin: 0;
}
#sidebar .share, #sidebar .buttons {
margin: 0 0 2.5rem;
padding: 0;
text-align: right;
}
#sidebar .share ul {
margin: 0;
}
#sidebar .share ul li {
border-bottom: 0px solid;
margin: 0 0 0 .25rem;
padding: 0;
}
#sidebar .share ul li a {
display: inline-flex;
}
@media (min-width: 768px) {
#sidebar .share, #sidebar .buttons {
text-align: left;
}
#sidebar .share strong {
display: none;
}
#sidebar .share ul li:first-child {
margin: 0;
}
}

#sidebar .attribute-form {
margin: 0;
}
.sidebar .actions {
margin: 2.5rem 0 1.5rem;
}
.sidebar .banner {
margin: 2.5rem 0 1.5rem;
padding: 2.5rem 1.5rem;
text-align: center;
color: #ffffff;
border-radius: .25rem;
background-color: #047857;
}
.sidebar .banner h3 {
font-size: 1.25rem;
line-height: 1.75rem;
font-weight: 500;
}
.sidebar .banner p {
margin-top: 1.5rem;
}
.sidebar #map {
width: 100%;
height: calc(100vh - 82px);
}

/*** Meta Section (events and lots) ***/
#main .container > .meta {
margin-bottom: 1.5rem;
color: #4b5563;
text-align: center;
}
body.lot #main ul.meta {
display: flex;
flex-direction: column;
margin: 1rem 0 0;
padding: 0;
list-style: none;
list-style-type: none;
}
body.lot #main ul.meta li {
margin: 1rem 0 0 0;
padding: 1rem;
display: flex;
list-style: none;
list-style-type: none;
color: #4b5563;
border-radius: .25rem;
border: 1px solid #d1d5db;
}
body.lot #main ul.meta li:nth-child(3) {
padding: 0;
}
@media (min-width: 1024px) {
body.lot #main ul.meta {
flex-direction: row;
margin: 2rem 0 0;
padding: 1rem;
border-radius: .25rem;
border: 1px solid #d1d5db;
}
body.lot #main ul.meta li {
margin: 0;
padding: 0;
border: 0px solid;
}
}
body.lot #main ul.meta li:nth-child(2) {
flex-direction: column;
}
body.lot #main ul.meta li:first-child div:nth-child(4) {
display: none;
}
@media (min-width: 768px) {
body.lot #main ul.meta li:nth-child(2) {
flex-direction: row;
}
body.lot #main ul.meta li:first-child div:nth-child(4) {
display: block;
}
}
body.lot #main ul.meta li:nth-child(3) {
color: #b91c1c;
background-color: #fef2f2;
border: 1px solid #b91c1c;
order: -1;
}
@media (min-width: 1024px) {
body.lot #main ul.meta li:nth-child(3) {
background-color: transparent;
border: 0px solid;
}
}
body.lot #main ul.meta li > * {
flex: 1;
text-align: center;
}
@media (min-width: 1024px) {
body.lot #main ul.meta li > * {
margin: 0 0 0 1rem;
flex: none;
text-align: left;
}
}
@media (min-width: 1280px) {
body.lot #main ul.meta li > * {
margin: 0 0 0 2.5rem;
}
}
body.lot #main ul.meta li:nth-child(2) > div:first-child {
padding-bottom: .75rem;
}
@media (min-width: 768px) {
body.lot #main ul.meta li:nth-child(2) > div:first-child {
padding-bottom: 0;
}
}
@media (min-width: 1024px) {
body.lot #main ul.meta li:first-child > div:first-child {
margin-left: 0;
}
body.lot #main ul.meta li:nth-child(3) > div:first-child {
margin-left: 0;
}
body.lot #main ul.meta li:nth-child(3) > div:last-child {
margin-right: 1rem;
}
}
@media (min-width: 1280px) {
body.lot #main ul.meta li:nth-child(3) > div:last-child {
margin-right: 2.5rem;
}
}
body.lot #main ul.meta strong {
color: #111827;
}
body.lot #main ul.meta .status {
background-color: #e5e7eb;
border-radius: .25rem;
display: inline-block;
flex: none;
font-size: .75rem;
line-height: 1rem;
text-transform: uppercase;
padding: .25rem .5rem;
vertical-align: middle;
}
body.lot #main ul.meta .warnings {
margin: 0;
font-weight: 500;
line-height: inherit;
border: 0px solid;
border-radius: 0;
box-shadow: none;
background: transparent;
text-transform: none;
transition-delay: 0s;
transition-duration: 0s;
transition-property: all;
transition-timing-function: ease;
place-content: center;
width: 100%;
padding: 1rem;
}
@media (min-width: 1024px) {
body.lot #main ul.meta .warnings {
width: auto;
padding: 0;
}
}
body.lot #main ul.meta .warnings, body.lot #main ul.meta .date, body.lot #main ul.meta .followers, body.lot #main .buttons .follow {
white-space: nowrap;
display: inline-flex;
align-items: center;
}
body.lot #main ul.meta .warnings, body.lot #main ul.meta .warnings strong {
color: #b91c1c;
}
body.lot #main ul.meta .warnings i, body.lot #main ul.meta .followers i, body.lot #main .buttons .follow i {
margin-right: .25rem;
display: inline-block;
width: 1rem;
height: 1rem;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
body.lot #main ul.meta .warnings i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23b91c1c'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z' /%3E%3C/svg%3E");
}
body.lot #main ul.meta .followers i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23111827'%3E%3Cpath d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' /%3E%3C/svg%3E");
}
body.lot #main .buttons .follow i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23ffffff'%3E%3Cpath d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' /%3E%3C/svg%3E");
}
body.lot #main ul.meta .warnings strong, body.lot #main ul.meta .followers strong {
margin-left: .25rem;
}
body.lot #main ul.meta .date time, body.lot #main ul.meta .date span {
margin-left: .25rem;
}
body.lot #main .modal .warnings .loading {
min-height: 4rem;
text-align: center;
}
body.lot #main .modal .warnings ol li {
color: #b91c1c;
}
body.lot #main .modal .warnings p {
font-size: .9rem;
font-style: italic;
}

/*** Overview section (Lots and Fakes) ***/
body.lot .overview {
margin-top: 1.5rem;
margin-bottom: 2.5rem;
display: block;
}
body.lot .overview > .images {
position: relative;
}
body.lot .overview > .worksets {
margin-top: 2rem;
border-top: 1px solid #e5e7eb;
}
.overview figure {
margin: 0 auto 1rem;
padding: 0;
list-style: none;
list-style-type: none;
background-color: transparent;
display: inline-block;
width: 100%;
}
.overview figure > div {
margin: 0 auto;
padding-bottom: 100%;
width: 100%;
position: relative;
overflow: hidden;
}
.overview figure div div {
position: absolute;
vertical-align: middle;
left: 0;
top: 0;
width: 100%;
height: 100%;
display: flex;
vertical-align: middle;
justify-content: center;
align-items: center;
}
.overview figure div div {
background-color: transparent;
}
body.fake .overview figure div div {
background-color: #cbd5e1;
/*background-color: #e5e7eb;*/
}
.overview figure div div img {
margin: auto;
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
line-height: 0;
vertical-align: middle;
position: relative;
display: inline-block;
}
.overview .live-table {
position: absolute;
display: block;
top: 0;
left: 0;
z-index: 10;
width: 100%;
}
.overview .live-table > div {
margin: 0 auto;
padding-bottom: 100%;
width: 100%;
position: relative;
overflow: hidden;
}
.overview .live-table > div > div {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
display: block;
margin: 0;
padding: 1.5rem;
background: rgba(187, 247, 208, .95);
text-align: center;
}
.overview .live-table > div > div table {
margin-bottom: 1rem;
}
.overview .live-table h2 {
font-style: italic;
font-size: 1.2rem;
margin-bottom: .75rem;
}
@media (min-width: 768px) {
body.fake .overview {
display: grid;
grid-template-columns: repeat(3,minmax(0,1fr));
gap: 2rem;
}
body.fake .overview .wide {
grid-column: span 2/span 2;
}
body.fake .overview .images > div {
display: grid;
grid-auto-flow: column;
grid-auto-columns: 1fr;
gap: 1rem;
}
body.fake .overview figure {
margin: 0 auto;
}
}
@media (min-width: 1024px) {
body.lot .overview {
display: grid;
gap: 2rem;
grid-template-columns: repeat(7,minmax(0,1fr));
}
body.lot .overview > .images {
grid-column: span 3/span 3;
}
body.lot .overview > .worksets {
grid-column: span 4/span 4;
margin-top: 0;
border-top: 0px solid;
}
}
body.lot .pricing, body.lot .timing {
margin-top: 1rem;
padding:  0 1rem;
line-height: 2rem;
display: grid;
grid-template-columns: repeat(2,minmax(0,1fr));	
}
/*
body.lot .timing:has(.countdown) {
display: block;
}
body.lot .timing:has(.countdown) > .countdown {
margin-top: .5rem;
}
@media (min-width: 768px) {
body.lot .timing:has(.countdown) {
display: grid;
}
body.lot .timing:has(.countdown) > .countdown {
margin-top: 0;
}
}
*/
body.lot .timing {
display: block;
}
body.lot .timing > .countdown {
margin-top: .5rem;
}
@media (min-width: 768px) {
body.lot .timing {
display: grid;
grid-template-columns: repeat(2,minmax(0,1fr));
}
body.lot .timing > .countdown {
margin-top: 0;
}
}
body.lot .timing {
padding-top: 1rem;
border-top: 1px solid #e5e7eb;
}
body.lot .pricing strong {
/*display: block;*/
display: flex;
flex-wrap: wrap;
font-size: 1.125rem;
line-height: 1.75rem;
}
body.lot .pricing s {
margin-left: .25rem;
font-weight: 500;
font-size: 1rem;
}
body.lot .pricing u {
cursor: pointer;
}
body.lot .pricing span {
/*margin: .25rem 0 .5rem;*/
/*margin: .25rem 2rem .5rem 0;*/
margin: .25rem auto .5rem 0;
padding: .25rem .5rem;
display: inline-block;
color: #000000;
font-weight: 600;
font-size: 1.5rem;
line-height: 2rem;
letter-spacing: .1em;
transition: background-color 200ms ease-in-out 0s;
background-color: #e5e7eb;
border-radius: .25rem;
/*
clear: both;
float:left;
*/
}
body.lot .pricing span.active {
background-color: #d1fae5;
}
body.lot .timing time, body.lot .timing span {
display: block;
white-space: nowrap;
}
body.lot .timing div:last-child {
display: flex;
}
body.lot .timing div div:last-child {
margin-left: 2.5rem;
}
body.lot .timing div div:last-child button.live {
margin-top: auto;
margin-bottom: .25rem;
padding: .25rem .5rem;
border-color: #dc2626;
color: #dc2626;
font-weight: 600;
font-size: .875rem;
letter-spacing: .1em;
line-height: 1;
border-width: 1px;
border-radius: 0;
background-color: #ffffff;
}
body.lot .timing div div:last-child button.live:hover {
border-color: #b91c1c;
color: #b91c1c;
}
body.lot .timing div div:last-child button.reminder {
margin-top: auto;
margin-bottom: 0;
padding: 0;
border: 0px solid;
line-height: 1;
width: 1.5rem;
height: 2rem;
box-shadow: none;
background-size: 2rem 2rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23dc2626'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9' /%3E%3C/svg%3E");
}
body.lot .timing div div:last-child button.reminder:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23b91c1c'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9' /%3E%3C/svg%3E");
}
body.lot .timing div div:last-child button.reminder.active {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc2626' viewBox='0 0 24 24' stroke='%23dc2626'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9' /%3E%3C/svg%3E");
}
body.lot .timing div div:last-child button.reminder.active:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23b91c1c' viewBox='0 0 24 24' stroke='%23b91c1c'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9' /%3E%3C/svg%3E");
}
body.lot .timing span.timer {
align-items: center;
display: flex;
white-space: nowrap;
}
body.lot .timing span.timer span {
margin: 0 .125rem;
padding: .5rem;
background-color: #e5e7eb;
line-height: 1.25rem;
min-width: 18px;
text-align: center;
border-radius: .25rem;
}
@media (min-width: 768px) {
body.lot .timing span.timer span {
padding: .25rem;
}
}
body.lot .timing span.timer span:first-child {
margin-left: 0;
}
body.lot .timing span.timer small {
margin: 0 .125rem;
font-size: .875rem;
line-height: 1.25rem;
min-width: 29px;
}
body.lot .note {
margin-top: 2rem;
padding: 1.5rem 1rem;
font-size: 1rem;
line-height: 1.25rem;
border-radius: .25rem;
background: #f3f4f6;
color: #374151;
}
body.lot .note p {
margin: 0;
font-style: italic;
}
body.lot .worksets > .buttons {
margin-top: 2.5rem;
/*display: inline-flex;*/
}
body.lot .swap-share-report {
margin-top: 4.5rem;
display: block;
}
@media (min-width: 520px) {
body.lot .swap-share-report {
display: flex;
align-items: flex-start;
}
}
body.lot .share-report {
flex-grow: 1;
}
body.lot .swap-share-report button.swap {
margin-bottom: 1.5rem;
background-color: #ffffff;
border: 1px dashed #6b7280;
color: #6b7280;
white-space: normal;
line-height: 1.25rem;
width: 120px;
box-shadow: none;
}
body.lot .swap-share-report button.swap:hover {
background-color: #f0f0f0;
border: 1px dashed #374151;
color: #374151;
}
@media (min-width: 520px) {
body.lot .swap-share-report button.swap {
margin-bottom: 0;
}
}
body.lot div.confirmation, body.lot div.processing, body.lot div.winning {
padding: 1rem 1rem 1.9rem;
text-align: center;
border-radius: .25rem;
position: relative;
}
body.lot div.confirmation .buttons {
margin-top: 0;
justify-content: center;
/*display: inline-flex;*/
}
body.lot div.confirmation h4 {
font-size: 1.125rem;
font-weight: 700;
}
body.lot div.processing h4 {
margin: 0;
font-size: 1rem;
font-weight: 500;
font-style: italic;
}
body.lot div.winning h4 {
margin: 0;
font-size: 1rem;
font-weight: 600;
font-style: italic;
}
body.lot div.share {
display: flex;
}
body.lot div.share > strong {
margin-left: auto;
}
body.lot div.report {
margin-top: 2rem;
display: flex;
align-items: center;
}
body.lot div.report > span {
white-space: nowrap;
margin-left: auto;
margin-right: .75rem;
font-size: .75rem;
line-height: 1rem;
}
body.lot div.report > button.report {
padding: .5rem .75rem;
letter-spacing: .1em;
color: #ef4444;
justify-content: center;
align-items: center;
font-weight: 600;
display: inline-flex;
box-shadow: none;
border: 1px solid #f87171;
border-radius: 9999px;
background-color: transparent;
}
body.lot div.report > button.report:active, body.lot div.report > button.report:hover {
color: #b91c1c;
border: 1px solid #b91c1c;
}
body.lot div.report > button.report i {
margin-right: .25rem;
display: inline-block;
width: 1rem;
height: 1rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23ef4444'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z' /%3E%3C/svg%3E");
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
body.lot div.report > button.report:active i, body.lot div.report > button.report:hover i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23b91c1c'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z' /%3E%3C/svg%3E");
}
body.lot button.flag {
position: absolute;
top: 1.7rem;
right: 1rem;
z-index: 10;
padding: 0;
color: #bec1c6;
justify-content: center;
align-items: center;
box-shadow: none;
border: 0px solid;
background-color: transparent;
}
body.lot td button.flag {
position: static;
}
@media (min-width: 640px) {
body.lot button.flag {
right: 1.5rem;
}
}
body.lot button.flag i {
display: inline-block;
width: 1rem;
height: 1rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23bec1c6'%3E%3Cpath fill-rule='evenodd' d='M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z' clip-rule='evenodd' /%3E%3C/svg%3E");
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
body.lot button.flag:active i, body.lot button.flag:hover i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23374151'%3E%3Cpath fill-rule='evenodd' d='M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
body.fake .info p {
display: flex;
align-items: flex-start;
}
body.fake .info p strong {
margin-right: 1.25rem;
flex: none;
width: 8rem;
}
body.fake .info .description {
margin: .75rem 0;
padding: .5rem 0;
}

/*** Image/Video Galleries ***/
.image-gallery {
margin: .75rem 0 1rem 0;
padding: .5rem 0;
grid-template-columns: repeat(4,minmax(0,1fr));
gap: 1rem;
display: grid;
list-style: none;
list-style-type: none;
}
.image-gallery li {
cursor: pointer;
list-style: none;
list-style-type: none;
}
.image-gallery li img {
width: 100%;
height: auto;
max-width: 100%;
}
.video-gallery {
margin: .75rem 0 1rem 0;
padding: 0;
list-style: none;
list-style-type: none;
}
.video-gallery li {
margin: 0;
padding: .5rem 0;
list-style: none;
list-style-type: none;
}
body.fake .image-gallery {
display: flex;
gap: none;
grid-template-columns: none;
margin: .75rem 0 1rem;
padding: .5rem 0;
}
body.fake .image-gallery li {
margin-left: 1rem;
}
body.fake .image-gallery li:first-child {
margin-left: 0;
}
body.fake .image-gallery li img {
height: 5rem;
max-width: none;
width: auto;
}
/*
body.lot .image-gallery-thumbnails-wrapper, body.lot .swiper-thumbs {
display: flex;
align-items: center;
position: relative;
padding: 0 2rem;
}
body.lot .image-gallery-left-nav, body.lot .image-gallery-right-nav, body.lot .swiper-thumbs .swiper-button-prev, body.lot .swiper-thumbs .swiper-button-next {
margin: 0;
padding: 0;
width: 2rem;
height: 100%;
background-size: 2rem 2rem;
background-position: center center;
background-repeat: no-repeat;
background-color: #ffffff;
display: inline-block;
flex-shrink: 1;
border-radius: 0;
border: 1px solid #ffffff;
box-shadow: none;
outline: none;
top: 0;
opacity: 1 !important;
}
body.lot .image-gallery-left-nav, body.lot .swiper-thumbs .swiper-button-prev {
left: 0;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23cccccc' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .image-gallery-left-nav:hover, body.lot .swiper-thumbs .swiper-button-prev:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23333333' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .image-gallery-right-nav, body.lot .swiper-thumbs .swiper-button-next {
right: 0;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23cccccc' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .image-gallery-right-nav:hover, body.lot .swiper-thumbs .swiper-button-next:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23333333' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .image-gallery-left-nav:after, body.lot .swiper-thumbs .swiper-button-prev:after {
content: none;
}
body.lot .image-gallery-right-nav:after, body.lot .swiper-thumbs .swiper-button-next:after {
content: none;
}
body.lot .image-gallery, body.lot .swiper-thumbs .swiper-wrapper {
margin: 0;
padding: 0;
gap: normal normal;
grid-template-columns: none;
display: flex;
list-style: none;
list-style-type: none;
text-align: center;
}
body.lot .image-gallery, body.lot .swiper-thumbs .swiper-wrapper {
place-content: normal;
}
@media (min-width: 1024px) {
body.lot .image-gallery:not(:has(li:nth-child(5))) {
place-content: center;
}
body.lot .swiper-thumbs .swiper-wrapper:not(:has(li:nth-child(5))) {
place-content: center;
}
}
body.lot .image-gallery li, body.lot .swiper-thumbs .swiper-wrapper li {
margin: 0;
padding: 0;
border: 0px solid;
list-style: none;
list-style-type: none;
background-color: transparent;
display: inline-block;
width: 100px;
}
body.lot .image-gallery li > div, body.lot .swiper-thumbs .swiper-wrapper li > div {
margin: 0 auto;
padding-bottom: 100%;
position: relative;
overflow: hidden;
opacity: .5;
border: 1px solid transparent;
transition: border .3s ease-out;
}
body.lot .image-gallery li.active > div, body.lot .image-gallery li:hover > div, 
body.lot .image-gallery li.swiper-slide-thumb-active > div, body.lot .image-gallery li.swiper-slide-thumb-active:hover > div, 
body.lot .image-gallery li.swiper-slide-active > div, body.lot .image-gallery li.swiper-slide-active:hover > div {
border: 1px solid #999;
opacity: 1;
}
body.lot .swiper-thumbs .swiper-wrapper li.active > div, body.lot .swiper-thumbs .swiper-wrapper li:hover > div, 
body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-thumb-active > div, body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-thumb-active:hover > div, 
body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-active > div, body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-active:hover > div {
border: 1px solid #999;
opacity: 1;
}
body.lot .image-gallery li div div, body.lot .swiper-thumbs .swiper-wrapper li div div {
position: absolute;
vertical-align: middle;
left: 0;
top: 0;
width: 100%;
height: 100%;
display: flex;
vertical-align: middle;
justify-content: center;
align-items: center;
background-color: #e5e7eb;
}
body.lot .image-gallery li div div img, body.lot .swiper-thumbs .swiper-wrapper li div div img {
margin: auto;
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
line-height: 0;
vertical-align: middle;
position: relative;
display: inline-block;
}
body.lot .image-gallery li div div .rating, body.lot .swiper-thumbs .swiper-wrapper li div div .rating {
margin-top: -3px;
width: 100%;
height: 3px;
position: absolute;
top: 100%;
left: 0;
z-index: 10;
}
*/
body.lot .swiper-thumbs {
display: flex;
align-items: center;
position: relative;
padding: 0 2rem;
}
body.lot .swiper-thumbs .swiper-button-prev, body.lot .swiper-thumbs .swiper-button-next {
margin: 0;
padding: 0;
width: 2rem;
height: 100%;
background-size: 2rem 2rem;
background-position: center center;
background-repeat: no-repeat;
background-color: #ffffff;
display: inline-block;
flex-shrink: 1;
border-radius: 0;
border: 1px solid #ffffff;
box-shadow: none;
outline: none;
top: 0;
opacity: 1 !important;
}
body.lot .swiper-thumbs .swiper-button-prev {
left: 0;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23cccccc' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .swiper-thumbs .swiper-button-prev:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23333333' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .swiper-thumbs .swiper-button-next {
right: 0;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23cccccc' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .swiper-thumbs .swiper-button-next:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='6 0 12 24' fill='none' stroke='%23333333' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}
body.lot .swiper-thumbs .swiper-button-prev:after {
content: none;
}
body.lot .swiper-thumbs .swiper-button-next:after {
content: none;
}
body.lot .swiper-thumbs .swiper-wrapper {
margin: 0;
padding: 0;
gap: normal normal;
grid-template-columns: none;
display: flex;
list-style: none;
list-style-type: none;
text-align: center;
}
body.lot .swiper-thumbs .swiper-wrapper {
place-content: normal;
}
@media (min-width: 1024px) {
body.lot .swiper-thumbs .swiper-wrapper:not(:has(li:nth-child(5))) {
place-content: center;
}
}
body.lot .swiper-thumbs .swiper-wrapper li {
margin: 0;
padding: 0;
border: 0px solid;
list-style: none;
list-style-type: none;
background-color: transparent;
display: inline-block;
width: 100px;
}
body.lot .swiper-thumbs .swiper-wrapper li > div {
margin: 0 auto;
padding-bottom: 100%;
position: relative;
overflow: hidden;
opacity: .5;
border: 1px solid transparent;
transition: border .3s ease-out;
}
body.lot .swiper-thumbs .swiper-wrapper li.active > div, body.lot .swiper-thumbs .swiper-wrapper li:hover > div, 
body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-thumb-active > div, body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-thumb-active:hover > div, 
body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-active > div, body.lot .swiper-thumbs .swiper-wrapper li.swiper-slide-active:hover > div {
border: 1px solid #999;
opacity: 1;
}
body.lot .swiper-thumbs .swiper-wrapper li div div {
position: absolute;
vertical-align: middle;
left: 0;
top: 0;
width: 100%;
height: 100%;
display: flex;
vertical-align: middle;
justify-content: center;
align-items: center;
background-color: #e5e7eb;
}
body.lot .swiper-thumbs .swiper-wrapper li div div img {
margin: auto;
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
line-height: 0;
vertical-align: middle;
position: relative;
display: inline-block;
}
body.lot .swiper-thumbs .swiper-wrapper li div div .rating {
margin-top: -3px;
width: 100%;
height: 3px;
position: absolute;
top: 100%;
left: 0;
z-index: 10;
}


body.lot .metrics {
margin: 2rem auto 0;
padding: .5rem 1rem 1rem;
width: 250px;
border: 1px solid #cccccc;
border-radius: .25rem;
text-align: center;
}
body.lot .metrics p {
margin: 0 0 .75rem;
font-weight: 500;
}
body.lot .metrics p.score {
margin: 0 0 1rem;
font-size: 2rem;
font-weight: 600;
}
body.lot .metrics p i.tooltip {
margin-left: .25rem;
display: inline-block;
position: relative;
width: 1rem;
height: .8rem;
cursor: help;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23000000'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z' /%3E%3C/svg%3E");
}
body.lot .metrics .quality {
margin: 0;
padding: 0;
width: 100%;
height: .5rem;
background: -moz-linear-gradient(left, red, yellow, green);
background: -webkit-gradient(linear, left top, right top, color-stop(0%, red), color-stop(50%, yellow), color-stop(100%, green));
background: -webkit-linear-gradient(left, red, yellow, green);
background: -o-linear-gradient(left, red, yellow, green);
background: -ms-linear-gradient(left, red, yellow, green);
background: linear-gradient(to right, red, yellow, green);
position: relative;
}
/*
body.lot .quality .value {
height: 100%;
background-color: transparent;
border: 0px solid;
border-right: 2px solid #ffffff;
}
*/
body.lot .quality .value {
border: 0 solid;
border-right: 0;
height: 10px;
width: 2px;
background: #111827;
position: absolute;
top: -1px;
}
body.lot .estimates {
margin: .5rem 0 0;
padding: 0;
width: 120px;
text-align: center;
/*float: left;*/
}
body.lot .estimates p {
font-size: .75rem;
font-weight: 600;
margin: 0 0 .3rem;
padding: 0;
line-height: 1.2rem;
}
body.lot .estimates .estimate {
margin: 0;
padding: 0;
width: 100%;
height: .35rem;
background: -moz-linear-gradient(left, green, yellow, red);
background: -webkit-gradient(linear, left top, right top, color-stop(0%, green), color-stop(50%, yellow), color-stop(100%, red));
background: -webkit-linear-gradient(left, green, yellow, red);
background: -o-linear-gradient(left, green, yellow, red);
background: -ms-linear-gradient(left, green, yellow, red);
background: linear-gradient(to right, green, yellow, red);
position: relative;
}
body.lot .estimates .estimate .value {
border: 0 solid;
border-right: 0;
height: 8px;
width: 2px;
background: #111827;
position: absolute;
top: -1px;
}




/*** Quality rating widget ***/
/*
body.lot .pane .image-rating input {
display: none;
}
body.lot .pane .image-rating label {
display: inline-block;
padding: 5px 10px;
cursor: pointer;
}
body.lot .pane .image-rating label span {
position: relative;
line-height: 22px;
}
body.lot .pane .image-rating label span:before,
body.lot .pane .image-rating label span:after {
content: '';
}
body.lot .pane .image-rating label span:before {
border: 1px solid #222021;
width: 20px;
height: 20px;
margin-right: 10px;
display: inline-block;
vertical-align: top;
}
body.lot .pane .image-rating label span:after {
background: #222021;
width: 14px;
height: 14px;
position: absolute;
top: 2px;
left: 4px;
transition: 300ms;
opacity: 0;
}
body.lot .pane .image-rating label input:checked+span:after {
opacity: 1;
}
*/

/*** alternative ***/
body.lot .image-score-widget {
margin: -1.5rem 0 2rem;
display: block;
text-align: center;
}
body.lot .image-score-widget p {
margin: 0 0 .5rem;
}
body.lot .image-score-widget p i.tooltip {
margin-left: .25rem;
display: inline-block;
position: relative;
width: 1rem;
height: .8rem;
cursor: help;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23000000'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z' /%3E%3C/svg%3E");
}
body.lot .image-score-widget .quality {
display: inline-flex;
}
body.lot .image-score-widget label {
margin: 0 0 0 .5rem;
}
body.lot .image-score-widget label span {
display: none;
}
body.lot .image-score-widget input[type=radio] {
border: 2px solid transparent;
padding: 0.5em;
-webkit-appearance: none;
display: block;
border-radius: .125rem;
}
body.lot .image-score-widget input[type=radio]:checked {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23111827'%3E%3Cpath d='M24 0h-24v24h24v-24z'/%3E%3C/svg%3E");
background-size: 6px 6px;
background-repeat: no-repeat;
background-position: center center;
border-color: #111827;
}
body.lot .image-score-widget input[type=radio]:focus {
outline-color: transparent;
}






/*** Panes ***/
.pane .view-list, .pane table, .pane .empty {
margin: 1.5rem 0;
}
.pane .empty {
text-align: center;
}
.pane .view-list figure {
clear: both;
margin: 1.5rem auto 1.5rem;
min-width: auto;
}
.pane .view-list figure.image {
display: table;
}
.pane .view-list figure img {
display: block;
margin: 0 auto;
max-width: 100%;
min-width: 100%;
height: auto;
}
.pane .view-list figcaption {
font-size: .875rem;
background: #ffffff;
padding: .25rem .5rem;
text-align: center;
}

/*** Content Fieldsets ***/
body.lot #main fieldset .body {
min-height: 3rem;
}
body.lot #main fieldset .body p, body.fake #main fieldset .body p {
display: flex;
align-items: flex-start;
}
body.lot #main fieldset .body p:last-child, body.fake #main fieldset .body p:last-child {
margin: 0;
}
body.lot #main fieldset .body p strong, body.fake #main fieldset .body p strong {
margin-right: 1.25rem;
flex: none;
width: 10rem;
}
body.lot #main fieldset .body p a, body.fake #main fieldset .body p a {
overflow-wrap: break-word;
word-wrap: break-word;
word-break: break-word;
}
body.lot #main fieldset .body div.description {
font-style: normal;
color: #374151;
}
body.lot #main fieldset > .certificate {
margin: .75rem 0 0;
padding: 1rem 1.5rem;
display: flex;
border-radius: .25rem;
background-color: #ffffff;
box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
}
body.lot #main fieldset > .certificate figure {
margin: auto 0;
align-items: center;
vertical-align: middle;
}
body.lot #main fieldset > .certificate figure a {
margin: auto 0;
display: block;
vertical-align: middle;
}
body.lot #main fieldset > .certificate figure img {
width: 5rem;
height: auto;
display: block;
}
body.lot #main fieldset > .certificate div {
margin-left: 1.25rem;
font-size: .875rem;
line-height: 1.25rem;
position: relative;
}
body.lot #main fieldset > .certificate div p {
margin: 0;
white-space: nowrap;
}
body.lot #main fieldset > .certificate div p strong {
width: auto;
}
body.lot #main fieldset > .certificate div p a {
color: #111827;
}
body.lot #main fieldset > .certificate div .cac {
display: block;
height: 30px;
margin: 0;
padding: 0;
position: absolute;
width: 50px;
z-index: 1;
right: -70px;
top: 5px;
}
@media (min-width: 1024px) {
body.lot #main fieldset.with-certification .content {
min-height: 10rem;
}
body.lot #main fieldset > .certificate {
position: absolute;
top: 75px;
left: 0;
padding-right: 1.5rem;
}
body.lot #main fieldset > .certificate div .cac {
right: -40px;
top: -10px;
transform: rotate(30deg);
}
body.lot #main fieldset > .tags {
float: right;
clear: right;
width: 70%;
}
}

/*** VCard ***/
.vcard {
margin-bottom: 2rem;
}
.vcard > figure {
width: 100%;
position: relative;
}
.vcard > div {
margin: 1rem 0 0;
}
.vcard > figure div {
cursor: pointer;
}
.vcard > figure img {
width: 100%;
height: auto;
max-width: 100%;
}
@media (min-width: 768px) {
.vcard {
display: flex;
}
.vcard > div {
margin: 0 0 0 2rem;
}
.vcard > figure {
width: 8rem;
flex: none;
}
.vcard .info {
flex-grow: 1;
}
.vcard #phone {
flex: none;
}
}
.vcard .categories, .vcard .address {
margin-bottom: .5rem;
color: #9ca3af;
}
.vcard .categories, .vcard .address {
display: flex;
}
.vcard .button.google-calendar {
margin-right: .5rem;
}
.vcard #phone button, .vcard #phone .button, .vcard .button.google-calendar, .vcard button.favorites {
display: inline-flex;
align-items: center;
}
.vcard .categories i, .vcard .address i, .vcard #phone i, .vcard .button.google-calendar i, .vcard button.favorites i {
flex: none;
margin-right: .5rem;
width: 1rem;
height: 1.3rem;
background-size: .825rem .825rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: inline-block;
}
.vcard #phone i, .vcard .button.google-calendar i, .vcard button.favorites i {
margin-right: .25rem;
height: 1rem;
align-items: center;
}
.vcard .address a {
color: #9ca3af;
}
.vcard .categories i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239ca3af'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10' /%3E%3C/svg%3E");
}
.vcard .address i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239ca3af'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z' /%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M15 11a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E");
}
.vcard #phone i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23ffffff'%3E%3Cpath d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' /%3E%3C/svg%3E");
}
.vcard .button.google-calendar i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 488 512'%3E%3Cpath fill='%23ffffff' d='M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z' /%3E%3C/svg%3E");
}
.vcard button.favorites i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23ffffff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z' /%3E%3C/svg%3E");
}
.vcard .categories ul {
margin: 0;
padding: 0;
display: inline;
list-style: none;
list-style-type: none;
}
.vcard .categories ul li {
display: inline;
list-style: none;
list-style-type: none;
}
.vcard .categories ul li:after {
content: ', ';
}
.vcard .categories ul li:last-child:after {
content: '';
}
.vcard .date {
color: #4b5563;
font-size: .875rem;
line-height: 1.25rem;
}


@media (min-width: 768px) {
body.auction .vcard > div {
align-items: flex-start;
display: flex;
width: 100%;
}
body.auction .vcard > div > div {
flex-grow: 1;
width: auto;
}
body.auction .vcard > div > div:last-child {
display: block;
flex-grow: 0;
flex-shrink: 0;
margin: 0 0 0 2.5rem;
}
}


/*** Description and Body ***/
.description, body.article #main .body {
margin-bottom: 1rem;
}
body.article #main .body h2, body.article #main .body h3 {
margin: 2rem 0 1.5rem;
}
body.article #main .body p {
text-indent: 2rem;
}
body.article #main .body figure {
clear: both;
display: table;
margin: 1.5rem auto 1.5rem;
min-width: auto;
text-align: left;
}
body.article #main .body figure img {
display: block;
margin: 0 auto;
max-width: 100%;
min-width: 100%;
height: auto;
}
body.article #main .body figcaption {
font-size: .875rem;
background: #ffffff;
padding: .25rem .5rem;
text-align: center;
}

/*** Hours and Links ***/
@media (min-width: 640px) {
.hours-links {
grid-template-columns: repeat(2, minmax(0, 1fr));
gap: 2rem;
display: grid;
}
}
.hours-links .hours {
padding: .5rem 0;
font-size: .875rem;
line-height: 1.25rem;
}
.hours-links .hours>div {
width: 100%;
max-width: 24rem;
display: flex;
}
.hours-links .hours>div span {
margin-left: .75rem;
flex: none;
white-space: nowrap;
}
.hours-links .hours>div span:first-child {
margin-left: 0;
}
.hours-links .hours>div span.spacer {
flex-grow: 1;
border: 0px solid;
border-bottom: 1px dotted #d1d5db;
}

/*** Button group ***/
.buttons {
margin-top: 1rem;
display: flex;
flex-wrap: wrap;
}
/*
body.business .buttons, body.club .buttons, body.event .buttons {
display: flex;
}
*/
.buttons > button, .buttons > .button {
margin-right: .5rem;
margin-bottom: .5rem;
}
.buttons > button:last-child {
margin-right: 0;
}
.buttons .website {
margin-right: .5rem;
display: flex;
align-items: center;
}
.buttons .website i {
margin-right: .25rem;
flex: none;
width: 1rem;
height: 1rem;
background-size: .825rem .825rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: inline-block;
align-items: center;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23ffffff'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z' /%3E%3C/svg%3E");
}

/*** Tags and Share ***/
ul.tags {
margin: .75rem 0 1.5rem;
}
.tags-share {
margin: 1.75rem 0 1.5rem;
}
.tags-share ul.tags {
margin: 0;
}
.share {
margin: 0 0 .75rem;
display: block;
}
.tags-share .share {
margin: .75rem 0 0;
text-align: right;
}
@media (min-width: 640px) {
.tags-share {
display: flex;
}
.tags-share ul.tags {
margin: .35rem 0 0 0;
flex-grow: 1;
}
.share {
display: inline-flex;
align-items: center;
}
.tags-share .share {
margin: 0 0 0 auto;
}
}
.share strong {
font-weight: 500;
}
.share .links {
margin-left: .25rem;
}
.share .links li {
margin-left: .25rem;
}
.share .links a {
padding: .5rem;
transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
transition-duration: .15s;
transition-timing-function: cubic-bezier(.4,0,.2,1);
border: 1px solid #9ca3af;
border-radius: 9999px;
color: #111827;
}
.share .links a:hover {
border: 1px solid #e5e7eb;
background-color: #e5e7eb;
}

/*** Coupons and Byline ***/
.auctions, .coupons, .byline {
margin-top: 2.5rem;
border-top: 1px solid #d1d5db;
padding-top: 1.5rem;
}
.auctions > h2, .coupons > h2, .byline > h2 {
margin-bottom: 1.25rem;
text-transform: uppercase;
font-size: .75rem;
line-height: 1rem;
font-weight: 700;
color: #4b5563;
}
.byline figure img {
width: 100%;
max-width: 8rem;
height: auto;
max-height: 8rem;
background: #ffffff;
border-radius: 9999px;
}
.byline .description {
font-size: .875rem;
line-height: 1.25rem;
}
.byline .links-url-email {
font-size: .875rem;
line-height: 1.25rem;
display: inline-flex;
align-items: center;
}
.byline .links-url-email > a {
margin-left: .5rem;
padding-left: .5rem;
color: #111827;
border-left: 1px solid #d1d5db;
}

/*** Lot Workset Forms ***/
#place-bid-form, #buy-now-form, #second-chance-form, #place-offer-form, #make-offer-form, #buyer-confirmation-form {
margin: 0;
padding: 1.5rem 1rem .6rem;
}
#place-bid-form, #buy-now-form, #second-chance-form {
background-color: #f3f4f6;
}
#place-offer-form, #make-offer-form {
background-color: #fffbeb;
}
#place-bid-form.active {
/*background-color: #d1fae5;*/
border: 1px solid #047857;
color: #047857;
}
#place-offer-form.active {
/*background-color: #fffbeb;*/
border: 1px solid #047857;
color: #047857;
}
#buyer-confirmation-form {
background-color: #d1fae5;
border: 1px solid #047857;
color: #047857;
}
#place-offer-form button.primary, #make-offer-form button.primary {
background-color: #f59e0b;
border-color: #f59e0b;
}
#place-offer-form button.primary:hover, #make-offer-form button.primary:hover {
background-color: #db8f0e;
border-color: #db8f0e;
}
.worksets .forms {
margin-top: 1rem;
}
.worksets .forms form:first-child {
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
}
.worksets .forms form:last-child {
border-bottom-left-radius: .25rem;
border-bottom-right-radius: .25rem;
}
.view-grid article.lot .worksets .forms {
display: none;
}
.worksets form label {
font-weight: 500;
}
.worksets form label i.tooltip {
margin-left: .25rem;
display: inline-block;
position: relative;
width: 1rem;
height: .8rem;
cursor: help;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23000000'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z' /%3E%3C/svg%3E");
}
.worksets form span.tooltip {
position: relative;
cursor: help;
border-bottom: 1px dotted #111827;
}
#buy-now-form label, #second-chance-form label, #make-offer-form label {
margin-bottom: 0;
}
#buy-now-form strong, #second-chance-form strong {
font-size: 1.5rem;
line-height: 2rem;
font-weight: 600;
}
.worksets form .form-action {
margin-bottom: 1rem;
}
/*
.worksets form p {
font-size: .85rem;
}
*/

@media (min-width: 640px) {
.worksets form .row {
display: flex;
}
#place-bid-form .row, #place-offer-form .row {
align-items: flex-end;
}
#buy-now-form .row, #second-chance-form .row, #make-offer-form .row {
align-items: center;
}
.worksets form .row > div {
margin-left: 1rem;
width: 33.333333%;
}
.worksets form .row > div:first-child {
margin-left: 0;
}
.worksets form .form-action button {
width: 100%;
}
.worksets form p nobr:first-child {
margin-right: 1rem;
}
}

/*** Business, Club, Event Forms ***/
@media (min-width: 640px) {
#business-form fieldset .row, #club-form fieldset .row, #event-form fieldset .row {
display: flex;
}
#business-form fieldset .row > div, #club-form fieldset .row > div, #event-form fieldset .row > div {
margin-left: 1rem;
}
#business-form fieldset .row > div:first-child, #club-form fieldset .row > div:first-child, #event-form fieldset .row > div:first-child {
margin-left: 0;
}
#business-form fieldset .region-city-postcode > div, #club-form fieldset .region-city-postcode > div, #event-form fieldset .region-city-postcode > div {
width: 33.333333%;
}
#business-form fieldset .street-street2 > div:first-child, #club-form fieldset .street-street2 > div:first-child, #event-form fieldset .street-street2 > div:first-child {
width: 55%;
}
#business-form fieldset .street-street2 > div:last-child, #club-form fieldset .street-street2 > div:last-child, #event-form fieldset .street-street2 > div:last-child {
width: 45%;
}
}
@media (min-width: 1024px) {
#business-form fieldset, #club-form fieldset, #event-form fieldset, #fake-form fieldset, body.lot #main fieldset, body.fake #main fieldset {
width: 100%;
display: table-cell;
position: relative;
}
#business-form fieldset legend, #club-form fieldset legend, #event-form fieldset legend, #fake-form fieldset legend, body.lot #main fieldset legend, body.fake #main fieldset legend {
margin: 0;
padding: 0 1.5rem 0 0;
display: block;
width: 30%;
float: left;
position: relative;
font-size: 1.125rem;
font-weight: 500;
line-height: 1.75rem;
}
#business-form fieldset > .description, #club-form fieldset > .description, #event-form fieldset > .description, #fake-form fieldset > .description, body.lot #main fieldset > .description, body.fake #main fieldset > .description {
position: absolute;
width: 30%;
top: 35px;
padding-right: 1.5rem;
}
#business-form fieldset .content, #club-form fieldset .content, #event-form fieldset .content, #fake-form fieldset .content, body.lot #main fieldset .content, body.fake #main fieldset .content {
display: block;
float: right;
width: 70%;
margin: 0;
}
body.lot #main #comment-form fieldset .content, body.lot #main #thread-form fieldset .content, body.lot #main #report-form fieldset .content {
float: none;
width: auto;
}
}

/*** Forum Posts Forms ***/
#post-form fieldset, #topic-form fieldset {
margin: 1.5rem 0 2.5rem;
}
#post-form .closure, #topic-form .closure, #comment-form .closure, #post-form .authorization, #topic-form .authorization, #comment-form .authorization, #authorization-modal .body {
text-align: center;
}
#post-form .closure p:last-child, #topic-form .closure p:last-child, #comment-form .closure p:last-child, #post-form .authorization p:last-child, #topic-form .authorization p:last-child, #comment-form .authorization p:last-child, #authorization-modal .body p:last-child {
margin-top: 1.5rem;
}
#post-form .form-group:first-child label, #topic-form .form-group:first-child label, #comment-form .form-group:first-child label {
font-size: 1.125rem;
line-height: 1.75rem;
}
#post-form .form-wrapper .form-group label.toggler, #topic-form .form-wrapper .form-group label.toggler {
font-size: .875rem;
line-height: 1.25rem;
}
#post-form .form-wrapper .form-action, #topic-form .form-wrapper .form-action {
text-align: right;
}
@media (min-width: 768px) {
#post-form .form-wrapper, #topic-form .form-wrapper {
display: flex;
align-items: center;
}
#post-form .form-wrapper .form-group, #topic-form .form-wrapper .form-group {
margin-bottom: 0;
}
#post-form .form-wrapper .form-action, #topic-form .form-wrapper .form-action {
flex: none;
margin-left: auto;
text-align: left;
}
}

/*** Filter Form ***/
.filter-form fieldset {
margin: 0;
width: 100%;
color: #4b5563;
border-radius: .25rem;
border: 1px solid #d1d5db;
}
.filter-form fieldset .content {
padding: 1rem;
background-color: transparent;
font-size: .875rem;
line-height: 1.25rem;
}
.filter-form .form-group input[type=text] {
font-size: .875rem;
line-height: 1.25rem;
}
.filter-form button.locate {
width: 2rem;
min-height: 38px;
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
}
.filter-form button.locate {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236b7280' d='M500 232h-29.334C459.597 131.885 380.115 52.403 280 41.334V12c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v29.334C131.885 52.403 52.403 131.885 41.334 232H12c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h29.334C52.403 380.115 131.885 459.597 232 470.666V500c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12v-29.334C380.115 459.597 459.597 380.115 470.666 280H500c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12zM280 422.301V380c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v42.301C158.427 411.84 100.154 353.532 89.699 280H132c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12H89.699C100.16 158.427 158.468 100.154 232 89.699V132c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V89.699C353.573 100.16 411.846 158.468 422.301 232H380c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h42.301C411.84 353.573 353.532 411.846 280 422.301zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z' /%3E%3C/svg%3E");
}
.filter-form .form-action button, .filter-form .form-action .button {
margin-left: .5rem;
vertical-align: bottom;
font-size: .875rem;
line-height: 1.25rem;
}
@media (min-width: 1024px) {
.filter-form fieldset .content {
display: flex;
align-items: center;
}
.filter-form .form-group label, .filter-form .form-group .error, .filter-form .form-group .description {
display: none;
}
.filter-form .form-group {
flex-grow: 1;
margin-bottom: 0;
}
.filter-form .form-action {
margin-left: .5rem;
flex: none;
display: flex;
align-items: center;
}
}
.filter-form .with-dropdown {
display: inline-block;
}
.filter-form .dropdown-select {
min-width: 13rem;
font-size: .875rem;
line-height: 1.25rem;
text-transform: none;
font-weight: 500;
color: #374151;
display: flex;
align-items: center;
background-color: #f9fafb;
}
.filter-form .dropdown-select strong {
margin-right: .5rem;
font-weight: 600;
}
.filter-form .dropdown-content {
font-size: .875rem;
line-height: 1.25rem;
}
.filter-form .filter-dropdown li > a, .filter-form .sort-dropdown li > a {
border-top: 1px solid #e5e7eb;
}
.filter-form .filter-dropdown li:first-child > a, .filter-form .sort-dropdown li:first-child > a {
border-top: 0px solid;
}
.filter-form .sort-dropdown li:nth-child(2n) > a {
border-top: 1px dashed #e5e7eb;
}
.filter-form .dropdown-content li > a strong {
margin-right: .5rem;
font-weight: 600;
}
.filter-form .form-wrapper {
margin-left: auto;
text-align: right;
/*
display: flex;
width: 100%;
*/
}
.filter-form .form-wrapper .form-group {
display: flex;
align-items: center;
margin-bottom: 1rem;
text-align: left;
}
.filter-form .form-wrapper .form-group:last-child {
margin-bottom: 0;
}
.filter-form .form-wrapper .form-group label {
/* margin: 0 .5rem 0 0; */
margin: 0 .5rem 0 auto;
color: #4b5563;
display: inline-block;
font-size: .75rem;
font-weight: 700;
line-height: 1rem;
text-transform: uppercase;
white-space: nowrap;
}
/*
.filter-form .form-wrapper .form-group:first-child label {
margin: 0 .5rem 0 0;
}
*/
.filter-form .filter-by {
align-items: center;
display: none;
}
.filter-form .filter-by .button {
margin: 0;
padding: .5rem .75rem;
background: #ffffff;
color: #4b5563;
font-size: .875rem;
line-height: 1.25rem;
border: 1px solid #6b7280;
border-left: 0px solid;
border-radius: 0;
}
.filter-form .filter-by .active {
background: #6b7280;
color: #ffffff;
}
.filter-form .filter-by .button:hover, .filter-form .filter-by .button:active {
background: #f3f4f6;
color: #374151;
}
.filter-form .filter-by .active:hover, .filter-form .filter-by .active:active {
border-color: #374151;
background: #374151;
color: #ffffff;
}
.filter-form .filter-by .button:first-child {
border-left: 1px solid #6b7280;
border-bottom-left-radius: .25rem;
border-top-left-radius: .25rem;
}
.filter-form .filter-by .button:last-child {
border-bottom-right-radius: .25rem;
border-top-right-radius: .25rem;
}
.filter-form .view-as {
/*margin-left: .75rem;*/
margin-left: auto;
align-items: center;
display: inline-flex;
}
.filter-form .view-as button, .filter-form .view-as .button {
margin: 0;
padding: .5rem .5rem;
background: #ffffff;
color: #6b7280;
font-size: .875rem;
line-height: 1.25rem;
border: 1px solid #6b7280;
border-radius: 0;
/*
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
*/
}
.filter-form .view-as button {
border: 1px solid transparent;
background: #6b7280;
color: #ffffff;
cursor: initial;
}
.filter-form .view-as button:hover, .filter-form .view-as button:active {
background: #374151;
}
.filter-form .view-as .button:hover, .filter-form .view-as .button:active {
background: #f3f4f6;
color: #374151;
}
.filter-form .view-as .grid {
border-bottom-left-radius: .25rem;
border-top-left-radius: .25rem;
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236b7280' d='M149.333 56v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zm181.334 240v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm32-240v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24zm-32 80V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm-205.334 56H24c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm386.667-56H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm0 160H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zM181.333 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24z' /%3E%3C/svg%3E");*/
}
.filter-form .view-as .list {
border-bottom-right-radius: .25rem;
border-top-right-radius: .25rem;
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236b7280' d='M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z' /%3E%3C/svg%3E");*/
}
.filter-form .view-as button svg, .filter-form .view-as .button svg {
display: inline-block;
height: 1rem;
margin: 0 auto;
width: 1rem;
}

@media (min-width: 768px) {
.filter-form .form-wrapper .form-group {
flex: none;
}
.filter-form .form-wrapper .form-group:first-child {
flex: auto;
display: none;
}
.filter-form .form-wrapper .form-group:last-child {
display: flex;
}
.filter-form .filter-by {
display: flex;
}
}


@media (min-width: 1280px) {
.filter-form .form-wrapper {
display: flex;
}
.filter-form .form-wrapper:has(.form-group:nth-child(2)) {
width: 100%;
margin-left: 0;
}
.filter-form .form-wrapper .form-group {
margin-bottom: 0;
}
.filter-form .form-wrapper .form-group:nth-child(3n) {
margin-left: auto;
}
.filter-form .form-wrapper .form-group label {
display: inline-block;
margin: 0 .5rem 0 0;
}
.filter-form .form-wrapper .form-group:nth-child(3n) label {
margin-left: .75rem;
}
.filter-form .view-as {
margin-left: .75rem;
}
}

/*** Attribute Form ***/
.attribute-form fieldset {
margin: 0;
border-bottom: 1px solid #e5e7eb;
}
.attribute-form fieldset legend {
width: 100%;
padding: .5rem 0;
font-size: 1rem;
}
.attribute-form fieldset legend strong {
float: left;
font-weight: 600;
display: block;
}
.attribute-form fieldset legend button {
float: right;
margin: .35rem 0 0 .5rem;
padding: 0 .375rem;
font-size: .75rem;
line-height: 1rem;
}
.attribute-form fieldset legend button.active {
border: 1px solid #9ca3af;
background-color: #9ca3af;
color: #ffffff;
}
.attribute-form fieldset legend button.active:hover {
border: 1px solid #374151;
background-color: #374151;
}
.attribute-form fieldset legend:after {
content: '';
display: table;
clear: both;
float: none;
height: 0;
}
.attribute-form fieldset .content {
padding: .75rem 0 1.25rem .75rem;
background-color: transparent;
border-radius: 0;
font-size: .875rem;
line-height: 1.25rem;
}
.attribute-form fieldset .form-group {
margin-bottom: .5rem;
}
.attribute-form fieldset .form-group input[type=text] {
font-size: .875rem;
line-height: 1.25rem;
}
.attribute-form fieldset .form-action {
padding: .75rem 0;
}
.attribute-block {
margin-top: 2rem;
}
.attribute-block .bars {
margin-bottom: 3rem;
}
#attribute-mtm-form .form-wrapper, #attribute-mnm-form .form-wrapper, #attribute-grd-form .form-wrapper {
margin: .5rem auto 0;
display: grid;
grid-template-columns: repeat(2, minmax(0, 1fr));
column-gap: .75rem;
}
#attribute-mtm-form .form-wrapper .form-group label, #attribute-mnm-form .form-wrapper .form-group label, #attribute-grd-form .form-wrapper .form-group label {
margin-bottom: 0;
}
#attribute-dt-form .form-wrapper, #attribute-prc-form .form-wrapper {
margin: .5rem auto 0;
max-width: 28rem;
display: flex;
}
#attribute-dt-form .form-wrapper > div, #attribute-prc-form .form-wrapper > div {
margin: 0 0 0 .75rem;
align-self: flex-end;
}
#attribute-dt-form .form-wrapper .form-group input, #attribute-prc-form .form-wrapper .form-group input {
font-size: .875rem;
line-height: 1.25rem;
}
#attribute-dt-form .form-wrapper > div:first-child, #attribute-prc-form .form-wrapper > div:first-child {
margin-left: 0;
}

/*** Forum Groups and Blocks ***/
body.forum #main .container > .description, body.topic #main .container > .description {
display: flex;
align-items: flex-start;
}
@media (min-width: 1024px) {
body.forum #main .container > .description, body.topic #main .container > .description {
align-items: center;
}
}
body.forum #main .container > .description p {
margin: 0;
flex-grow: 1;
}
body.topic #main .container > .description > div {
margin: 0;
flex-grow: 1;
display: flex;
align-items: center;
}
body.topic #main .container > .description > div figure {
flex: none;
}
body.topic #main .container > .description > div > div {
flex-grow: 1;
margin-left: .5rem;
}
body.topic #main .container > .description > div img {
width: 4rem;
height: 4rem;
border-radius: 9999px;
}
body.topic #main .container > .description > div .date, body.topic #main .container > .description > div .date a {
color: #9ca3af;
font-size: .875rem;
line-height: 1.25rem;
}
body.forum #main .container > .description button, body.topic #main .container > .description button {
margin-left: .75rem;
flex: none;
font-size: .75rem;
line-height: 1rem;
}
body.forum #main .container > .description button progress:indeterminate, body.topic #main .container > .description button progress:indeterminate {
background-size: 1.5rem 1.5rem;
}
body.forum #main .container > .description button var, body.topic #main .container > .description button var {
margin-left: .5rem;
padding: .125rem .25rem;
color: #374151;
display: inline-block;
font-style: normal;
border-radius: .25rem;
background-color: #e5e7eb;
}
body.community #main .group h2, body.community #main .block h2, body.forum #main .group h2, body.topic #main .group h2, body.topic #main .block h2 {
margin: .75rem 0 .5rem;
padding: .5rem .75rem;
width: 100%;
font-size: 1.125rem;
line-height: 1.75rem;
color: #ffffff;
background-color: #374151;
display: flex;
align-items: flex-start;
flex-wrap: wrap;
border-radius: .25rem;
text-transform: none;
font-weight: 600;
}
body.topic #main .group h2 .actions {
width: 100%;
}
@media (min-width: 768px) {
body.topic #main .group h2 .actions {
margin-left: auto;
width: auto;
}
}
body.topic #main .group h2 .actions button, body.topic #main .group h2 .actions .button {
padding: 0 .75rem;
font-size: .875rem;
font-weight: 600;
text-transform: none;
}
body.topic #main .group h2 .actions button:hover, body.topic #main .group h2 .actions .button:hover {
color: #ffffff;
}
body.community #main .block h2 {
background-color: #9ca3af;
}
body.community #main #online-users h2, body.topic #main #last-visitors h2 {
color: #111827;
background-color: #e5e7eb;
}
body.community #main .group h2 strong, body.community #main .block h2 strong, body.forum #main .group h2 strong {
flex-grow: 1;
font-weight: 600;
display: block;
}
body.community #main .group h2 button, body.community #main .block h2 button, body.forum #main .group h2 button {
flex: none;
margin: .35rem 0 0 .5rem;
padding: 0 .375rem;
font-size: .75rem;
line-height: 1rem;
background-color: transparent;
}
body.community #main .group h2 button, body.forum #main .group h2 button {
color: #9ca3af;
border: 1px solid #9ca3af;
}
body.community #main .block h2 button {
color: #d1d5db;
border: 1px solid #d1d5db;
}
body.community #main .group h2 button:hover, body.community #main .block h2 button:hover, body.forum #main .group h2 button:hover {
background-color: #f3f4f6;
border: 1px solid #f3f4f6;
}
body.community #main .group h2 button:hover, body.forum #main .group h2 button:hover {
color: #374151;
}
body.community #main .block h2 button:hover {
color: #9ca3af;
}
body.community #main .block .content {
padding: .5rem 0;
}
body.topic #main .group .content .pagination {
margin-top: 1rem;
margin-bottom: 2rem;
}
body.community #main .group .row, body.forum #main .group .row {
margin: .5rem 0 1.5rem;
padding: .75rem;
display: flex;
}
@media (min-width: 1024px) {
body.community #main .group .row, body.forum #main .group .row {
display: grid;
grid-template-columns: repeat(9,minmax(0,1fr));
gap: .75rem;
}
}
body.community #main .group .row:nth-child(2n), body.forum #main .group .row:nth-child(2n) {
background-color: #f9fafb;
}
body.community #main .block .row, body.home #our-community .block .row {
margin: .5rem 0;
padding: .75rem;
display: flex;
border-bottom: 1px solid #d1d5db;
}
body.community #main .block .row:last-child, body.home #our-community .block .row:last-child {
border-bottom: 0px solid;
}
body.community #main .group .row .subforums, body.forum #main .group .row .subforums {
flex-grow: 1;
display: flex;
align-items: flex-start;
font-size: .875rem;
line-height: 1.25rem;
}
body.forum #main .group .row .topic {
flex-grow: 1;
display: flex;
align-items: flex-start;
}
@media (min-width: 1024px) {
body.community #main .group .row .subforums, body.forum #main .group .row .subforums {
grid-column: span 4/span 4;
}
body.forum #main .group .row > .topic {
grid-column: span 6/span 6;
}
}
body.community #main .group .row .subforums svg, body.forum #main .group .row .subforums svg {
width: 1.5rem;
height: 1.5rem;
}
body.community #main .group .row .subforums h3, body.forum #main .group .row .subforums h3 {
font-size: 1.125rem;
line-height: 1.75rem;
margin-bottom: .5rem;
font-weight: 700;
}
body.community #main .group .row .subforums p, body.forum #main .group .row .subforums p {
margin-bottom: .5rem;
}
body.community #main .group .row .subforums ul, body.forum #main .group .row .subforums ul {
margin: .5rem 0;
padding-left: .75rem;
list-style: none;
list-style-type: none;
display: block;
background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 12' stroke='%239ca3af'%3E%3Cline stroke-width='3' x1='0' y1='0' x2='0' y2='12' /%3E%3Cline stroke-width='3' x1='0' y1='12' x2='24' y2='12' /%3E%3C/svg%3E");
background-position: 0 6px;
background-repeat: no-repeat;
background-size: 10px 5px;
}
body.community #main .group .row .subforums ul li, body.forum #main .group .row .subforums ul li {
display: inline-block;
font-weight: 700;
}
body.community #main .group .row .subforums ul li a:before, body.forum #main .group .row .subforums ul li a:before {
content: '• '; /* &bull; */
}
body.community #main .group .row .subforums ul li a:after, body.forum #main .group .row .subforums ul li a:after {
content: ", ";
}
body.community #main .group .row .subforums > div:first-child, body.forum #main .group .row .subforums > div:first-child {
flex: none;
}
body.community #main .group .row .subforums > div:last-child, body.forum #main .group .row .subforums > div:last-child {
margin-left: .75rem;
flex-grow: 1;
}
body.forum #main .group .row .topic h3 {
margin: 0 0 .5rem;
font-weight: 500;
font-size: 1rem;
}
body.forum #main .group .row .topic.unread h3 > a {
font-weight: 700;
}
body.forum #main .group .row .topic h3 ul {
margin: 0 0 0 .5rem;
padding: 0;
list-style: none;
list-style-type: none;
display: inline-block;
font-size: .875rem;
line-height: 1.25rem;
}
body.forum #main .group .row .topic h3 ul li {
display: inline-block;
}
body.forum #main .group .row .topic h3 ul li a {
padding: 0 .25rem;
color: #4b5563;
display: inline-block;
background: #f3f4f6;
}
body.forum #main .group .row .topic .meta {
color: #9ca3af;
font-size: .875rem;
line-height: 1.25rem;
}
body.community #main .group .row > .total, body.forum #main .group .row > .total {
display: none;
}
@media (min-width: 1024px) {
body.community #main .group .row > .total, body.forum #main .group .row > .total {
display: block;
}
}
body.community #main .block .row .total, body.home #our-community .block .row .total {
margin-left: .5rem;
flex: none;
}
body.community #main .group .row .total span, body.forum #main .group .row .total span {
display: block;
color: #9ca3af;
font-size: .875rem;
line-height: 1.25rem;
}
body.community #main .group .row .subforums .total, body.forum #main .group .row .subforums .total {
display: inline-block;
}
body.forum #main .group .row .topic .total {
display: inline-block;
font-size: .875rem;
line-height: 1.25rem;
}
@media (min-width: 1024px) {
body.community #main .group .row .subforums .total, body.forum #main .group .row .subforums .total {
display: none;
}
body.forum #main .group .row .topic .total {
display: none;
}
}
body.community #main .group .row .subforums .total span, body.forum #main .group .row .subforums .total span {
display: inline-block;
margin-right: 1rem;
}
body.forum #main .group .row .topic .total div {
display: inline-block;
margin-right: 1rem;
}
body.forum #main .group .row .topic .total span {
display: inline-block;
}
body.community #main .block .row .total span, body.home #our-community .block .row .total span {
padding: .125rem .25rem;
display: block;
color: #111827;
background: #e5e7eb;
font-size: .75rem;
line-height: 1rem;
border-radius: .25rem;
} 
body.community #main .block .row .topic, body.home #our-community .block .row .topic {
margin-left: .5rem;
flex-grow: 1;
font-size: .875rem;
line-height: 1.25rem;
}
body.community #main .block .row .feature, body.home #our-community .block .row .feature {
flex-grow: 1;
font-size: .875rem;
line-height: 1.25rem;
}
body.community #main .group .row .last-post, body.forum #main .group .row .last-post {
flex: none;
margin-left: .75rem;
display: flex;
/* vertical-align: top; */
align-items: flex-start;
color: #4b5563;
font-size: .875rem;
line-height: 1.25rem;
}
@media (min-width: 1024px) {
body.community #main .group .row .last-post, body.forum #main .group .row .last-post {
margin-left: 0;
grid-column: span 3/span 3;
}
body.forum #main .topics .row .last-post {
margin-left: 0;
grid-column: span 2/span 2;
}
}
body.community #main .block .row img, body.home #our-community .block .row img, body.community #main .group .row .last-post img, body.forum #main .group .row .last-post img {
width: 2rem;
height: 2rem;
border-radius: 9999px;
}
body.community #main .block .row .date, body.community #main .block .row .date a, 
body.home #our-community .block .row .date, body.home #our-community .block .row .date a, 
body.community #main .group .row .last-post .date, body.community #main .group .row .last-post .date a, 
body.forum #main .group .row .last-post .date, body.forum #main .group .row .last-post .date a {
color: #9ca3af;
}
body.community #main .block .row > figure, body.home #our-community .block .row > figure, body.community #main .group .row .last-post > figure, body.forum #main .group .row .last-post > figure {
flex: none;
}
body.community #main .group .row .last-post > figure .time-ago, body.community #main .group .row .last-post > figure .time-ago a, 
body.forum #main .group .row .last-post > figure .time-ago, body.forum #main .group .row .last-post > figure .time-ago a {
color: #9ca3af;
}
@media (min-width: 1024px) {
body.community #main .group .row .last-post > figure .time-ago, body.forum #main .group .row .last-post > figure .time-ago {
display: none;
}
}
body.community #main .group .row .last-post > div, body.forum #main .group .row .last-post > div {
display: none;
}
@media (min-width: 1024px) {
body.community #main .group .row .last-post > div, body.forum #main .group .row .last-post > div {
display: block;
margin-left: .5rem;
flex-grow: 1;
font-size: .875rem;
line-height: 1.25rem;
}
}
body.community #main .time {
margin-top: .75rem;
padding: 0 .75rem;
font-size: .875rem;
line-height: 1.25rem;
color: #9ca3af;
}
body.community #main #online-users .content, body.topic #main #last-visitors .content {
padding: .75rem;
}
body.community #main #online-users ul, body.topic #main #last-visitors ul {
margin: 0;
padding: 0;
display: inline;
list-style: none;
list-style-type: none;
font-size: .875rem;
line-height: 1.25rem;
}
body.community #main #online-users ul li, body.topic #main #last-visitors ul li {
display: inline;
list-style: none;
list-style-type: none;
}
body.community #main #online-users ul li:after, body.topic #main #last-visitors ul li:after {
content: ', ';
}
body.community #main #online-users ul li:last-child:after, body.topic #main #last-visitors ul li:last-child:after {
content: '';
}
body.forum #main .actions {
margin-bottom: 1rem;
} 
body.forum #main button.mark {
font-size: 1rem;
font-weight: 600;
text-transform: none;
display: inline-flex;
align-items: center;
}
body.forum #main button.mark i {
flex: none;
margin-right: .5rem;
width: 1rem;
height: .65rem;
display: inline-block;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: inline-block;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%236b7280'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
body.topic #main .share-actions .actions {
margin: 0 0 .75rem;
}
@media (min-width: 768px) {
body.topic #main .share-actions {
display: flex;
align-items: center;
width: 100%;
}
body.topic #main .share-actions .actions {
text-align: right;
flex-grow: 1;
}
}
body.topic #multi-quotes-panel {
position: fixed;
right: .75rem;
bottom: 2.5rem;
display: flex;
align-items: center;
padding: .5rem;
color: #ffffff;
border-radius: .25rem;
background-color: #333333;
z-index: 100;
}
body.topic #multi-quotes-panel .clear {
margin-left: .25rem;
outline: none;
cursor: pointer;
border: 0;
flex: none;
vertical-align: top;
background: none;
box-shadow: none;
width: 1rem;
height: 1rem;
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
body.topic #multi-quotes-panel .clear {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' viewBox='0 0 320 512'%3E%3Cpath fill='%239ca3af' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z' /%3E%3C/svg%3E");
}
body.topic #multi-quotes-panel .clear:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z' /%3E%3C/svg%3E");
}

/*** Intro Block ***/
#intro  {
margin-top: 6rem;
padding: 0 1rem;
position: relative;
width: auto;
}
@media (min-width: 640px) {
#intro  {
padding: 0;
}
}
#intro .slide {
border-radius: .25rem;
position: relative;
width: 100%;
background-color: #f3f4f6;
background-size: cover;
background-repeat: no-repeat;
background-position: 0;
}
@media (min-width: 768px) {
#intro .slide {
height: 24rem;
}
}
#intro .slide > div {
margin:  0 auto;
text-align: center;
padding:  2.5rem 2.5rem;
max-width: 64rem;
color: #ffffff;
}
@media (min-width: 640px) {
#intro .slide > div {
padding:  5rem 0;
}
}
#intro .slide h1 {
margin: 0;
display: inline-block;
background-color: rgba(0, 0, 0, 0.5);
font-weight: 700;
color: #f59e0b;
font-size: 3rem;
line-height: 1;
}
#intro .slide .text {
padding-top: 1.25rem;
}
#intro .slide .text p {
display: inline;
font-size: 1.5rem;
line-height: 2rem;
background-color: rgba(0, 0, 0, 0.5);
}

/*** Our Benefits Block ***/
#our-benefits {
padding: 4rem 1rem;
text-align: center;
color: #6b7280;
}
@media (min-width: 640px) {
#our-benefits {
padding: 4rem 0;
}
}
#our-benefits .accented {
margin: 1rem auto 0;
max-width: 56rem;
}
#our-benefits dl {
margin: 2.5rem 0 1rem;
text-align: left;
}
#our-benefits dl > div {
position: relative;
padding-bottom: 1.5rem;
}
@media (min-width: 768px) {
#our-benefits dl {
display: grid;
-moz-column-gap: 2rem;
column-gap: 2rem;
grid-template-columns: repeat(2,minmax(0,1fr));
row-gap: 2.5rem;
padding-bottom: 1.5rem;
}
#our-benefits dl > div {
padding-bottom: 0;
}
}
#our-benefits dt {
margin: 0 0 0 4rem;
font-size: 1.125rem;
line-height: 1.5rem;
font-weight: 500;
color: #111827;
}
#our-benefits dd {
margin: .5rem 0 0 4rem;
font-size: 1rem;
line-height: 1.5rem;
}
@media (min-width: 768px) {
#our-benefits dt {
margin-right: 2rem;
}
#our-benefits dd {
margin-right: 2rem;
}
}
#our-benefits dd.icon {
margin: 0;
padding: 0.75rem;
line-height: 1rem;
position: absolute;
justify-content: center;
align-items: center;
border-radius: .375rem;
background-color: #6366f1;
color: #ffffff;
}
#our-benefits svg {
display: block;
width: 1.5rem;
height: 1.5rem;
}

/*** Our Community Block ***/
#our-community {
padding: 4rem 1rem;
text-align: center;
color: #6b7280;
}
@media (min-width: 640px) {
#our-community {
padding: 4rem 0;
}
}
#our-community .accented {
margin: 1rem auto 0;
max-width: 56rem;
}
#our-community > div {
margin-top: 2rem;
padding-bottom: 1.5rem;
text-align: left;
}
#our-community > div > div {
margin-top: 1.5rem;
}
#our-community > div > div h4 {
color: #111827;
font-size: 1.5rem;
line-height: 2rem;
font-weight: 500;
}
@media (min-width: 768px) {
#our-community > div {
display: grid;
-moz-column-gap: 0;
column-gap: 0;
grid-template-columns: repeat(2,minmax(0,1fr));
border-top: 1px solid #e5e7eb;
}
#our-community > div > div {
margin-top: 0;
padding-bottom: 0;
}
#our-community > div > div:last-child {
border-left: 1px solid #e5e7eb;
}
#our-community > div > div h4 {
padding: 1rem 1.5rem 0 1.5rem;
}
#our-community > div > div .content {
padding: 0 1.5rem;
}
}

/*** For Businesses Block ***/
#for-businesses {
padding: 2.5rem 1rem 4rem;
}
@media (min-width: 640px) {
#for-businesses {
padding: 2.5rem 0 4rem;
}
}
#for-businesses:after {
clear: both;
content: '';
display: table;
float: none;
}
#for-businesses > div {
position: relative;
}
#for-businesses > div:last-child {
padding-top: 2rem;
}
@media (min-width: 768px) {
#for-businesses > div:first-child {
width: 30%;
float: left;
padding-right: 1.25rem;
}
#for-businesses > div:last-child {
width: 70%;
float: left;
padding-top: 0;
padding-left: 1.25rem;
}
}
@media (min-width: 1024px) {
#for-businesses > div:first-child {
width: 50%;
float: left;
}
#for-businesses > div:last-child {
width: 50%;
float: left;
}
}
@media (min-width: 1280px) {
#for-businesses > div:last-child {
padding-top: 2.5rem;
}
}
#for-businesses img {
display: block;
width: 100%;
height: 100%;
border-radius: .25rem;
-o-object-fit: cover;
object-fit: cover;
}
#for-businesses .actions {
margin: 1rem auto 0;
text-align: left;
}

/*** Recent Businesses Block ***/
#recent-businesses {
padding: 4rem 1rem;
text-align: center;
}
@media (min-width: 640px) {
#recent-businesses {
padding: 4rem 0;
}
}
#recent-businesses > div {
margin-top: 2.5rem;
}
@media (min-width: 768px) {
#recent-businesses > div {
display: grid;
-moz-column-gap: 1rem;
column-gap: 1rem;
grid-template-columns: repeat(2,minmax(0,1fr));
row-gap: .5rem;
padding-bottom: 1.5rem;
}
}

/*** Recent Articles & Upcoming Events Blocks ***/
#recent-articles, #upcoming-events {
padding: 2.5rem 1rem;
text-align: center;
background: #f3f4f6;
}
@media (min-width: 640px) {
#recent-articles, #upcoming-events {
padding: 2.5rem 0;
}
}
#recent-articles .swiper-container, #upcoming-events .swiper-container {
margin: 1.25rem auto 0;
overflow: hidden;
padding: 0;
position: relative;
z-index: 1;
}
#recent-articles article, #upcoming-events article {
height: 100%;
display: flex;
flex-direction: column;
margin-bottom: 0;
}
#recent-articles article .content, #upcoming-events article .content {
flex-grow: 1;
}
@media (min-width: 1024px) { 
#upcoming-events .view-block article.event .content {
padding: .75rem 1rem 1.5rem;
}
}





article.business .summary, article.club .summary, article.article .summary {
margin-bottom: 0;
}
article.coupon .actions {
margin-top: 1rem;
text-align: center;
}


article.auction .image-gallery li {
cursor: default;
}
body.auction .countdown {
font-size: 1.25rem;
line-height: 1.75rem;
text-align: right;
}
body.auction .countdown .timer > strong small {
margin-left: .235rem;
}
body.auction .countdown .timer > strong {
font-weight: normal;
}
@media (min-width: 768px) {
body.auction .countdown {
text-align: center;	
}
body.auction .countdown .timer > strong {
display: block;
}
body.auction .countdown .timer > strong span {
font-size: 2rem;
font-weight: 500;
}
}

@media (min-width: 768px) {
body.auction .view-grid article.lot .content {
padding-bottom: 4rem;
}
body.auction .view-grid article.lot .content .status, body.auction .view-grid article.lot .content .actions {
position: absolute;
bottom: 1rem;
width: calc(100% - 2rem);
left: 0;
margin: 0 1rem;
}
}


/*** Recommended Lots & Viewed Lots Blocks ***/
#recommended-lots, #viewed-lots {
padding: 2.5rem 1rem;
text-align: center;
background: #ffffff;
}
@media (min-width: 640px) {
#recommended-lots, #viewed-lots {
padding: 2.5rem 0;
}
}
#recommended-lots .container, #viewed-lots .container {
padding-top: 3rem;
/* border-top: 1px solid #9ca3af; */
border-top: 1px solid #e5e7eb;
}
#recommended-lots .swiper, #viewed-lots .swiper {
margin-top: 2rem;
}

/*** Modals ***/
.modal .result {
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
z-index: 40;
position: absolute;
width: 100%;
height: 100%;
align-content: center;
align-items: center;
display: flex;
border-radius: 0.25rem;
background-color: #ffffff;
}
.modal .result > div {
width: 100%;
text-align: center;
}
.modal .result p {
margin: .5rem 0 1.25rem;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
color: rgb(107, 114, 128);
font-size: 0.875rem;
line-height: 1.25rem;
}
.modal .loader {
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
z-index: 50;
position: absolute;
width: 100%;
height: 100%;
align-content: center;
align-items: center;
display: flex;
border-radius: 0.25rem;
background-color: #ffffff;
}
.modal .loader > div {
width: 100%;
text-align: center;
}
.modal .loader > div .spinner {
margin: 0 auto;
display: block;
background-size: 2.5rem 2.5rem;
background-position: center center;
background-repeat: no-repeat;
width: 2.5rem;
height: 2.5rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38 38' stroke='%23aaaaaa'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18' /%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite' /%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.modal .loader p {
margin: .5rem 0 0;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
color: rgb(107, 114, 128);
font-size: 0.875rem;
line-height: 1.25rem;
}
#localize-modal .content {
max-width: 32rem;
}
#rating-modal .body {
overflow-y: auto;
height: 225px;
}
#rating-modal .body ul {
margin: 0;
padding: 0;
list-style: none;
list-style-type: none;
display: grid;
grid-template-columns: repeat(2, minmax(0, 1fr));
gap: .75rem;
}
#rating-modal .body ul li {
margin: 0;
padding: 0;
list-style: none;
list-style-type: none;
display: block;
}
#rating-modal .body ul li span {
margin-right: .5rem;
display: inline-block;
padding: .25rem .5rem;
border-radius: .25rem;
background-color:  #ffffff;
border: 1px solid #9ca3af;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4.21875px 7px -1.0625px, rgba(0, 0, 0, 0.055) 0px 2.0625px 3px -0.703125px;
color: #6b7280;
font-size: .875rem;
line-height: 1.25rem;
font-weight: 700;
text-decoration: none;
text-transform: uppercase;
transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
white-space: nowrap;
text-align: center;
position: relative;
}
#rating-modal .body ul li span.positive {
background-color: #ecfdf5;
color: #059669;
border-color: #059669;
}
#rating-modal .body ul li span.negative {
background-color: #fef2f2;
color: #dc2626;
border-color: #dc2626;
}

/*** GDPR Modal ***/
#gdpr-modal {
width: 100%;
z-index: 50;
left: 0;
bottom: 0;
position: fixed;
color: #ffffff;
background-color: #000000;
}
#gdpr-modal .container {
padding: 1rem 0.75rem;
}
@media (min-width: 640px) {
#gdpr-modal .container {
padding: 1rem 0;
}
}
#gdpr-modal a {
color: #ffffff;
}
#gdpr-modal .content {
display: flex;
}
#gdpr-modal .content>:not([hidden])~:not([hidden]) {
margin-left: 0.75rem;
margin-right: 0;
}
#gdpr-modal .content p {
flex-grow: 1;
font-size: .875rem;
line-height: 1.25rem;
}
#gdpr-modal .content .button {
flex: none;
align-self: flex-start;
}