html,
body {
margin: 0;
padding: 0;
height: 100%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
scroll-behavior: smooth;
}
body {
font-size: 16px;
line-height: 22px;
background: #ffffff;
color: #111827;
-webkit-text-size-adjust: none;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-family: Nunito,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
*, *:before, *:after {
-webkit-box-sizing: inherit;
-moz-box-sizing: inherit;
box-sizing: inherit;
}
a {
color: #047857;
text-decoration: none;
}
h1, h2, h3, h4, h5, h6 {
margin: 0 0 1rem;
font-weight: 600;
}
h1 {
font-size: 2.25rem;
line-height: 2.5rem;
}
blockquote, dd, dl, figure, pre {
margin: 0;
}
audio, canvas, embed, iframe, img, object, svg, video {
display: block;
vertical-align: middle;
}
hr {
border: solid #e5e7eb;
border-width: 1px 0 0;
height: 0;
}
p, address {
margin: 0 0 1rem;
padding: 0;
}





/*** Dividers ***/
.divider-with-text {
margin-top: 2.5rem;
align-items: center;
display: flex;
white-space: nowrap;
}
.divider-with-text div {
margin-left: .75rem;
color: #4b5563;
flex-shrink: 1;
text-align: center;
}
.divider-with-text hr {
margin-left: .75rem;
flex-grow: 1;
}
.divider-with-text hr:first-child {
margin-left:  0;
}





/*** Fields ***/
fieldset, button, input, select, textarea {
font-family: inherit;
font-size: 100%;
line-height: 1.5;
margin: 0;
}
fieldset {
margin: 1.5rem 0;
padding: 0;
border: 0;
box-shadow: none;
background: transparent;
}
fieldset legend {
color: #111827;
font-size: 1.125rem;
font-weight: 500;
line-height: 1.75rem;
padding-inline-start: 0;
padding-inline-end: 0;
}
fieldset .description {
margin: 0 0 1rem;
padding: 0;
color: #4b5563;
font-style: italic;
text-align: left;
}
fieldset > .description {
font-size: .875rem;
line-height: 1.25rem;
margin: .25rem 0 1rem;
}
fieldset p strong {
width: 8rem;
display: inline-block;
font-weight: 600;
}
fieldset .content {
background-color: #f3f4f6;
border-radius: .25rem;
color: #374151;
padding: 1.25rem 1rem;
position: relative;
}
@media (min-width: 640px) {
fieldset .content {
padding: 1.5rem;
}
}





.hidden {
display: none;
}
.left {
text-align: left;
}
.center {
text-align: center;
}
.right {
text-align: right;
}
.uppercase {
text-transform: uppercase;
}


/*** Table ***/
table {
text-align: left;
width: 100%;
border-collapse: collapse
}
table thead tr {
letter-spacing: .05em;
text-align: left;
font-size: .875rem;
line-height: 1.25rem;
}
table thead tr th {
margin: 0;
padding: .5rem 0;
border-bottom: 2px solid #d1d5db;
}
table tbody tr:nth-child(odd) {
background-color: #f9fafb;
}
table tbody tr td {
margin: 0;
padding: .5rem 0;
}


/*** Buttons ***/
.button, button {
cursor: pointer;
display: inline-block;
padding: .5rem .75rem;
border-radius: .25rem;
background-color:  #ffffff;
border: 1px solid #9ca3af;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4.21875px 7px -1.0625px, rgba(0, 0, 0, 0.055) 0px 2.0625px 3px -0.703125px;
color: #6b7280;
font-size: 1rem;
line-height: 1.5rem;
font-weight: 700;
text-decoration: none;
text-transform: uppercase;
transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
white-space: nowrap;
text-align: center;
position: relative;
}
.button:focus, button:focus {
/*
outline: 2px solid transparent;
outline-offset: 2px;
*/
outline: none;
}
.button:hover, button:hover {
color: #374151;
background-color: #f3f4f6;
border: 1px solid #9ca3af;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.button.tiny, button.tiny {
font-size: .75rem;
line-height: 1rem;
}
.button.small, button.small {
font-size: .875rem;
line-height: 1.25rem;
}
.button.large, button.large {
padding: .75rem 1.25rem;
font-size: 1.25rem;
line-height: 1.75rem;
}
.button.primary, button.primary {
color: #ffffff;
border-color: #10b981;
background-color: #10b981;
}
.button.primary:hover, button.primary:hover {
border-color: #047857;
background-color: #047857;
}
.button.secondary, button.secondary {
color: #ffffff;
border-color: #6b7280;
background-color: #6b7280;
}
.button.secondary:hover, button.secondary:hover {
border-color: #374151;
background-color: #374151;
}
.button.alternative, button.alternative {
color: #ffffff;
border-color: #6366f1;
background-color: #6366f1;
}
.button.alternative:hover, button.alternative:hover {
border-color: #4338ca;
background-color: #4338ca;
}
.button.transparent, button.transparent {
border-color: transparent;
background-color: transparent;
box-shadow: none;
}
.button.transparent:hover, button.transparent:hover {
border-color: transparent;
background-color: transparent;
box-shadow: none;
}
.button.current, button.current {
border-color: #111827;
background-color: #111827;
}
.button.primary.submitted, button.primary.submitted {
color: #10b981;
}
.button.primary.submitted:hover, button.primary.submitted:hover {
color: #047857;
}
.button.add, .button.remove, button.add, button.remove {
width: 3rem;
min-height: 42px;
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
}
.button.add, button.add {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z' /%3E%3C/svg%3E");
}
.button.remove, button.remove {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z' /%3E%3C/svg%3E");
}

















/*** Form Fields ***/
.actions, .form-action {
text-align: right;
}
.form-group {
margin-bottom: 1rem;
}
.form-group label {
margin-bottom: .5rem;
font-weight: 600;
display: block;
}
.form-group label em {
font-style: italic;
font-weight: 500;
}
.form-group strong {
font-weight: 600;
}
.form-group input[type='text'], .form-group input[type='password'], .form-group input[type='email'], .form-group input[type='tel'], .form-group input[type='url'], .form-group input[type='date'], .form-group input[type='time'], .form-group input[type='datetime-local'], .form-group input[type='number'], .form-group textarea, .form-group select, .form-group .tree-select button {
width: 100%;
padding: .5rem .75rem;
border: 1px solid #9ca3af;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
border-radius: .25rem;
background-color: #ffffff;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font-weight: 500;
text-align: left;
text-transform: none;
color: #111827;
cursor: auto;
}
.form-group select, .form-group .tree-select button {
padding: .5rem 2.5rem .5rem .75rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2371717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
background-position: right .5rem center;
background-repeat: no-repeat;
background-size: 1.5rem 1.5rem;
opacity: 1;
-webkit-print-color-adjust: exact;
print-color-adjust: exact;
/*color-adjust: exact;*/
}
.form-group .with-dropdown {
position: relative;
}
.form-group .dropdown-select {
width: 100%;
padding: .5rem 2.5rem .5rem .75rem;
border: 1px solid #9ca3af;
background-color: #ffffff;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2371717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
background-size: 1.5rem 1.5rem;
background-position: right .5rem center;
background-repeat: no-repeat;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
border-radius: .25rem;
color: #9ca3af;
-webkit-print-color-adjust: exact;
print-color-adjust: exact;
/*color-adjust: exact;*/
min-height: 42px;
}
.form-group .dropdown-select > div {
margin-right: .5rem;
padding: .25rem .5rem;
color: #374151;
font-size: .875rem;
line-height: 1.25rem;
align-items: center;
display: inline-flex;
border-radius: .25rem;
background-color: #e5e7eb;
}
.form-group .dropdown-select > div button {
margin-left: .25rem;
padding: 0;
border: 0;
box-shadow: none;
color: #9ca3af;
cursor: pointer;
background-color: #e5e7eb;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 352 512'%3E%3Cpath fill='%239ca3af' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /%3E%3C/svg%3E");
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
width: .75rem;
height: .75rem;
}
.form-group .dropdown-select > div button:hover {
color: #374151;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 352 512'%3E%3Cpath fill='%23374151' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /%3E%3C/svg%3E");
}
.form-group input:disabled, .form-group textarea:disabled, .form-group select:disabled, .form-group .tree-select button:disabled {
color: #d1d5db;
cursor: not-allowed;
background-color: #f3f4f6;
}
.form-group label.checkbox {
display: grid;
grid-template-columns: 1rem auto;
gap: .75rem;
font-weight: 500;
}
.form-group input[type='checkbox'] {
-webkit-appearance: none;
appearance: none;
background-color: #fff;
margin: 0;
font: inherit;
color: currentColor;
width: 1.25rem;
height: 1.25rem;
border: 1px solid #9ca3af;
border-radius: .25rem;
transform: translateY(-0.075em);
display: grid;
place-content: center;
}
.form-group input[type='checkbox']:before {
content: '';
width: 0.65em;
height: 0.65em;
transform: scale(0);
transition: 120ms transform ease-in-out;
box-shadow: inset 1em 1em #059669;
background-color: CanvasText;
transform-origin: bottom left;
clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.form-group input[type='checkbox']:checked:before {
transform: scale(1);
}
.form-group input[type='checkbox']:focus {
/*
outline: max(2px, 0.15em) solid currentColor;
outline-offset: max(2px, 0.15em);
*/
outline: none;
}
.form-group input[type='checkbox']:disabled {
color: #eeeeee;
cursor: not-allowed;
}


.form-group .radios {
display: flex;
align-items: flex-start;
}
.form-group .radios > * {
margin-right: 1rem;
}
.form-group .radios > *:last-child {
margin-right: 0;
}
.form-group label.radio {
display: grid;
grid-template-columns: 1rem auto;
gap: .75rem;
font-weight: 500;
}
.form-group input[type='radio'] {
-webkit-appearance: none;
appearance: none;
width: 1.25rem;
height: 1.25rem;
vertical-align: top;
transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
transition-timing-function: cubic-bezier(.4,0,.2,1);
transition-duration: 200ms;
background-repeat: no-repeat;
background-position: 50%;
background-size: contain;
background-color: #fff;
border: 1px solid #9ca3af;
border-radius: 9999px;
cursor: pointer;
display: grid;
place-content: center;
}
.form-group input:checked[type='radio'] {
background-color: #059669;
background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E");
border-color: #059669;
}


.form-group label.toggler {
display: inline-flex;
align-items: center;
font-weight: 500;
cursor: pointer;
}
.form-group label.toggler > div {
margin-right: .75rem;
position: relative;
}
.form-group label.toggler input[type='checkbox'] {
display: none;
}
.form-group label.toggler input[type='checkbox']:checked + div {
display: block;
}
.form-group label.toggler input[type='checkbox']:checked ~ .dot {
background-color: #10b981;
transform: translateX(100%);
}
.form-group label.toggler > div .line {
width: 2.5rem;
height: 1rem;
border-radius: 9999px;
background-color: #9ca3af;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.form-group label.toggler > div .dot {
left: -.25rem;
top: -.25rem;
transition: all .3s ease-in-out;
width: 1.5rem;
height: 1.5rem;
position: absolute;
border-radius: 9999px;
background-color: #ffffff;
}
.form-group > p, .form-group .description {
color: #4b5563;
font-style: italic;
text-align: left;
margin: 0 0 1rem;
padding: .5rem 0;
font-size: .75rem;
line-height: 1rem;
}
.form-group input.error, .form-group select.error, .form-group textarea.error, .form-group button.error, .form-group .dropdown-select.error, .form-group #categories.error > div {
border-color: #dc2626;
}
.form-group p.error {
color: #dc2626;
}
.form-group .with-spinner {
position: relative;
}
.form-action .with-spinner {
position: relative;
display: inline-block;
}
.form-group .with-prefix {
position: relative;
}
.form-group .with-prefix .prefix {
left: .5rem;
z-index: 10;
position: absolute;
padding: .5rem 0;
border-top: 1px solid transparent;
border-bottom: 1px solid transparent;
}
.form-group .with-prefix input {
padding-left: 1.5rem;
}
.form-group .with-toggler {
position: relative;
}
.form-group .with-toggler input {
padding-right: 1.5rem;
}
.form-group .with-toggler button {
padding: 0;
background-color: transparent;
width: 1rem;
height: 1rem;
top: .75rem;
right: .75rem;
position: absolute;
z-index: 2;
color: #9ca3af;
border: 0;
box-shadow: none;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
}
.form-group .with-toggler button.show {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%239ca3af'%3E%3Cpath d='M10 12a2 2 0 100-4 2 2 0 000 4z' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z' /%3E%3C/svg%3E");
}
.form-group .with-toggler button.hide {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%239ca3af'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z' /%3E%3Cpath d='M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z' /%3E%3C/svg%3E");
}
.form-group .flatpickr {
width: 100%;
white-space: nowrap;
display: flex;
}
.form-group .flatpickr input {
flex-grow: 1;
border-right-width: 0;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
}
.form-group .flatpickr button {
flex: none;
border-right-width: 0;
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
background-color: rgb(229, 231, 235);
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
color: rgb(156, 163, 175);
width: 2.625rem;
}
.form-group .flatpickr button:hover {
background-color: rgb(209, 213, 219);
color: rgb(55, 65, 81);
}
.form-group .flatpickr button:last-child {
border-width: 1px;
border-top-right-radius: .25rem;
border-bottom-right-radius: .25rem;
}
.form-group .flatpickr button.clear {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 352 512'%3E%3Cpath fill='%239ca3af' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /%3E%3C/svg%3E");
}
.form-group .flatpickr button.clear:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 352 512'%3E%3Cpath fill='%23374151' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /%3E%3C/svg%3E");
}
.form-group .flatpickr button.calendar {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 448 512'%3E%3Cpath fill='%239ca3af' d='M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z' /%3E%3C/svg%3E");
}
.form-group .flatpickr button.calendar:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 448 512'%3E%3Cpath fill='%23374151' d='M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z' /%3E%3C/svg%3E");
}
.form-group .flatpickr button.clock {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 512 512'%3E%3Cpath fill='%239ca3af' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z' /%3E%3C/svg%3E");
}
.form-group .flatpickr button.clock:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' role='img' viewBox='0 0 512 512'%3E%3Cpath fill='%23374151' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z' /%3E%3C/svg%3E");
}

.form-group .dates > div {
border-bottom: 1px dotted #cccccc;
margin-bottom: .5rem;
}
.form-group .dates > div:last-child {
border-bottom:  0px solid;
margin-bottom: 0;
}
.form-group .dates > div > div {
margin-bottom: 1rem;
}
.form-group .dates > div > div label {
font-weight: 500;
display: block;
}
.form-group .dates > div > div:last-child label {
display: none;
}
@media (min-width: 640px) {
.form-group .dates > div {
display: flex;
border-bottom: 0px solid;
}
.form-group .dates > div > div {
margin: 0 0 0 1rem;
width: 25%;
}
.form-group .dates > div > div:first-child {
margin-left: 0;
flex-grow: 1;
width: auto;
}
.form-group .dates > div > div:last-child {
width: 3rem;
display: flex;
flex-direction: column;
}
.form-group .dates > div > div label {
font-weight: 500;
display: none;
}
.form-group .dates > div:first-child > div label {
display: block;
}
}

.form-group .hours {
display: grid;
grid-template-columns: repeat(3,minmax(0,1fr));
gap: 1rem;
font-size: .875rem;
line-height: 1.25rem;
}
@media (min-width: 640px) {
.form-group .hours {
grid-template-columns: repeat(4,minmax(0,1fr));
}
}
@media (min-width: 1024px) {
.form-group .hours {
grid-template-columns: repeat(7,minmax(0,1fr));
}
}
.form-group .hours label {
margin: 0;
}
.form-group .hours .button {
width: 100%;
height: 2.5rem;
line-height: 2.5rem;
padding: 0;
cursor: pointer;
color: rgb(55, 65, 81);
text-transform: none;
overflow: hidden;
}
.form-group .hours .button.open {
background-color: rgb(5,150,105);
color: #ffffff;
}
.form-group .hours .button.closed {
background-color: rgb(220,38,38);
color: #ffffff;
}
.form-group .hours .button input {
position: absolute;
opacity: 0;
}
.form-group .hours .options {
margin-top: .5rem;
}
.form-group .hours .options select {
margin: 0 0 .5rem;
padding: .25rem 1.25rem .25rem .5rem;
}
.form-group .multiinput > div {
margin-bottom: 1rem;
display: flex;
}
.form-group .multiinput > div:last-child {
margin-bottom: 0;
}
.form-group .multiinput > div input {
flex-grow: 1;
margin-right: 1rem;
}


.form-group .image-uploader button input[type='file'] {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0;
}
.form-group .image-uploader .preview {
margin: -.25rem -.25rem 1rem;
padding: 0;
flex: 1 1 0%;
flex-wrap: wrap;
display: flex;
list-style: none;
list-style-type: none;
}
.form-group .image-uploader .preview li {
display: inline-block;
list-style: none;
list-style-type: none;
padding: .25rem;
height: 10rem;
}
.form-group .image-uploader .preview figure {
display: block;
width: 100%;
height: 100%;
color: #ffffff;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
position: relative;
outline: none;
border-radius: .25rem;
background-color: rgb(243,244,246);
}
.form-group .image-uploader .preview figure:focus {
outline: none;
}
.form-group .image-uploader .preview img {
width: auto;
position: sticky;
height: 100%;
border-radius: .25rem;
background-attachment: fixed;
}
.form-group .image-uploader .preview figcaption {
z-index: 20;
width: 100%;
height: 100%;
top: 0;
position: absolute;
padding: .5rem .75rem;
overflow: hidden;
font-size: .75rem;
line-height: 1rem;
flex-direction: column;
display: none;
border-radius: .25rem;
overflow-wrap: break-word;
}
.form-group .image-uploader .preview figure:hover figcaption {
display: flex;
}
.form-group .image-uploader .preview figcaption h3 {
font-size: .75rem;
line-height: 1rem;
flex: 1 1 0%;
}
.form-group .image-uploader .preview figcaption div {
display: flex;
}
.form-group .image-uploader .preview figcaption var {
padding: .25rem .25rem .25rem 1.25rem;
font-size: .75rem;
line-height: 1rem;
color: #ffffff;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z' /%3E%3C/svg%3E");
background-size: 1rem 1rem;
background-position: left center;
background-repeat: no-repeat;
background-color: transparent;
}
.form-group .image-uploader .preview figcaption button {
margin-left: auto;
padding: .25rem;
border-radius: .25rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z' /%3E%3C/svg%3E");
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
color: #ffffff;
border: 0px solid;
box-shadow: none;
width: 1.5rem;
height: 1.5rem;
}
.form-group .image-uploader .preview figcaption button:hover {
background-color: rgb(209,213,219);
}
.form-group .image-uploader .dropzone {
position: relative;
height: 10rem;
justify-content: center;
align-items: center;
display: flex;
border-width: 1px;
border-style: dashed;
border-color: rgb(156,163,175);
border-radius: .25rem;
background-color: #ffffff;
cursor: pointer;
}
.form-group .image-uploader .dropzone > div {
flex-direction: column;
align-items: center;
display: flex;
position: absolute;
font-weight: 400;
color: #9ca3af;
}
.form-group .image-uploader .dropzone > div svg {
color: #e5e7eb;
}
.form-group .image-uploader .dropzone.active > div svg {
color: #10b981;
}
.form-group .image-uploader .dropzone input[type='file'] {
width: 100%;
height: 100%;
opacity: 0;
}






.form-action button, .form-action .button, .actions button, .actions .button {
margin-left: .5rem;
}
.form-action button:first-child, .form-action .button:first-child, .actions button:first-child, .actions .button:first-child {
margin-left: 0;
}













/*** Container ***/
.container {
margin: 0 auto;
/*width: 100%;*/
position: relative;
}
@media (min-width: 640px) {
.container {
max-width: 640px;
}
}
@media (min-width: 768px) {
.container {
max-width: 768px;
}
}
@media (min-width: 1024px) {
.container {
max-width: 1024px;
}
}
@media (min-width: 1280px) {
.container {
max-width: 1280px;
}
}

/*** Header ***/
#header {
top: 0;
width: 100%;
position: fixed;
background-color: rgb(31,41,55);
z-index: 50;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
/*
#header > .container {
padding: 0 .75rem;
width: auto;
}
@media (min-width: 640px) {
#header > .container {
padding: 0;
width: 100%;
}  
}
#header .container:after {
clear: both;
}
*/

#header > .container {
padding: .875rem .75rem;
position: relative;
}
/*
@media (min-width: 640px) {
#header > .container {
padding: .875rem 0;
}  
}
*/
@media (min-width: 768px) {
#header > .container {
padding: .875rem 0;
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
}  
}


#header #logo {
/*padding: .875rem 0;*/
padding: 0;
/*float: left;*/
}
#header #logo a {
display: block;
width: 24px;
height: 34px;
overflow: hidden;
}
#header #logo img {
margin: 0;
padding: 0;
display: block;
}
@media (min-width: 640px) {
#header #logo a {
width: 223px;
height: 34px;
overflow: visible;
}
}


#header .toggler {
margin: 0;
padding: 0;
width: 2rem;
height: 2rem;
position: absolute;
top: 1rem;
right: .75rem;
z-index: 100;
background-size: 1.25rem 1.25rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23d1d5db' stroke-width='1'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4 6h16M4 12h16M4 18h16' /%3E%3C/svg%3E");
}
#header .toggler:hover {
background-color: #374151;
color: #ffffff;
}
@media (min-width: 768px) {
#header .toggler {
display: none;
}
}


#header #navigation {
list-style: none;
list-style-type: none;
display: none;
clear: both;
margin: 1rem 0 0;
padding: .5rem 0 0;
border-top: 1px solid #4b5563;
}
#header #navigation .dropdown-content {
list-style: none;
list-style-type: none;
display: block;
clear: both;
margin: 0;
padding: 0;
width: auto;
background: transparent;
box-shadow: none;
max-height: none;
position: static;
}
@media (min-width: 768px) {
#header #navigation {
display: flex;
clear: none;
align-items: center;
margin: 0 0 0 1.5rem;
padding: 0;
border: 0px solid;
}
#header #navigation .dropdown-content {
margin: .5rem 0 0 0;
padding: .5rem 0;
position: absolute;
right: 0;
transform-origin: top right;
width: 14rem;
border-radius: .25rem;
background-color: #ffffff;
box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
z-index: 20;
max-height: 24rem;
overflow-y: auto;
}
}
#header #navigation > li, #header #navigation .dropdown-content > li {
list-style: none;
list-style-type: none;
margin: .5rem 0 0;
padding: 0;
}
@media (min-width: 768px) {
#header #navigation > li {
margin: 0 0 0 .75rem;
}
#header #navigation > li:first-child {
margin: 0;
}
#header #navigation .dropdown-content > li {
margin: 0;
}
}
#header #navigation > li > a, #header #navigation > li > button, #header #navigation .dropdown-content > li > a {
display: block;
background-color: transparent;
padding: .5rem .75rem;
border-radius: .375rem;
font-size: .875rem;
line-height: 1.25rem;
font-weight: 500;
color: #d1d5db;
border: 0px solid;
text-transform: none;
box-shadow: none;
transition-property: none;
}
#header #navigation .dropdown-content > li > a {
padding: .5rem .75rem .5rem 1.75rem;
}
#header #navigation > li > a:hover, #header #navigation > li > button:hover, #header #navigation .dropdown-content > li > a:hover {
background-color: #374151;
color: #ffffff;
}
#header #navigation > li.current > a, #header #navigation > li.active > a, #header #navigation .dropdown-content > li.current > a, #header #navigation .dropdown-content > li.active > a {
background-color: #111827;
}
@media (min-width: 768px) {
#header #navigation .dropdown-content > li > a, #header #navigation .dropdown-content > li.current > a, #header #navigation .dropdown-content > li.active > a {
align-items: center;
background-color: #ffffff;
color: #374151;
padding: .5rem 1rem;
}
#header #navigation .dropdown-content > li > a:hover {
background-color: #f3f4f6;
color: #374151;
}
}
#header #navigation > li > .dropdown-button {
width: 100%;
text-align: left;
padding: .5rem 1.5rem .5rem .75rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23d1d5db'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
background-size: .75rem .75rem;
background-position: right .5rem center;
background-repeat: no-repeat;
/*display: none;*/
color: #d1d5db;
}
@media (min-width: 768px) {
#header #navigation > li > .dropdown-button {
width: auto;
/*display: block;*/
}
#header #navigation #resources-dropdown { 
width: 10rem;
}
}
#header #navigation > li.current > .dropdown-button {
background-color: #111827;
}




/*
#header .actions {
margin: 1rem 0 0;
}
*/

#header .actions {
position: absolute;
top: .875rem;
right: 3.75rem;
z-index: 10;
display: flex;
text-align: left;
}

/*
@media (min-width: 640px) {
#header .actions {
right: 0;
}  
}
*/
@media (min-width: 768px) {
#header .actions {
position: static;
/*padding:  0 .5rem;*/
padding: 0;
/*text-align: right;*/
margin-left: auto;
}
}

#header .actions > div > button {
padding: 0;
display: flex;
align-items: center;
color: #d1d5db;
font-size: 0.875rem;
line-height: 1.25rem;
font-weight: 600;
text-transform: none;
white-space: nowrap;
border-color: transparent;
background-color: transparent;
box-shadow: none;
}
#header .actions #profile-button {
margin-left: 2rem;
}
#header .actions #notifications-button, #header .actions #messages-button {
margin-left: .75rem;
}
#header .actions #notifications-button > button, #header .actions #messages-button > button {
padding: .25rem;
color: #9ca3af;
}
#header .actions > div > button:hover {
color: #ffffff;
border-color: transparent;
background-color: transparent;
box-shadow: none;
}
#header .actions #notifications-button > button svg, #header .actions #messages-button > button svg {
width: 1.5rem;
height: 1.5rem;
}
#header .actions #profile-button > button img {
margin-right: .5rem;
width: 2rem;
height: 2rem;
background: transparent;
border-radius: 9999px;
border: 1px solid #9ca3af;
}
#header .actions #profile-button > button:hover img {
border: 1px solid #ffffff;
}
#header .actions #notifications-button > button span, #header .actions #messages-button > button span {
position: absolute;
padding: 0 .25rem;
top: 0;
right: 0;
text-align: center;
font-size: .5rem;
/*line-height: 1rem;*/
line-height: 1.375;
background-color: #16a34a;
color: #ffffff;
border-radius: .25rem;
}


#header .actions #notifications-dropdown, #header .actions #messages-dropdown {
padding: 0;
width: 16rem;
max-height: none;
overflow-y: auto;
text-align: left;
}
@media (min-width: 768px) {
#header .actions #notifications-dropdown {
width: 20rem;
}
#header .actions #messages-dropdown {
width: 24rem;
}
}
#header .actions #notifications-dropdown .header, #header .actions #messages-dropdown .header {
display: flex;
padding: .5rem 1rem;
background-color: #e5e7eb;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
color: #374151;
vertical-align: middle;
border-top: 1px solid #e5e7eb;
border-left: 1px solid #e5e7eb;
border-right: 1px solid #e5e7eb;
align-items: center;
}
#header .actions #notifications-dropdown .header h3, #header .actions #messages-dropdown .header h3 {
margin: 0;
padding: 0;
flex-grow: 1;
}
#header .actions #messages-dropdown .header button {
flex: none;
margin-left: .5rem;
padding: .5rem .75rem;
font-size: .75rem;
line-height: 1rem;
}
#header .actions #notifications-dropdown .body, #header .actions #messages-dropdown .body {
background-color: #ffffff;
overflow-y: auto;
height: 50vh;
position: relative;
border-left: 1px solid #e5e7eb;
border-right: 1px solid #e5e7eb;
}
#header .actions #notifications-dropdown .body > div, #header .actions #messages-dropdown .body > div {
margin: 0;
padding: .5rem 1rem;
font-size: 0.875rem;
line-height: 1.25rem;
color: #374151;
text-align: center;
}
#header .actions #notifications-dropdown .footer, #header .actions #messages-dropdown .footer {
display: flex;
padding: .5rem 1rem;
background-color: #e5e7eb;
border-bottom-left-radius: .25rem;
border-bottom-right-radius: .25rem;
color: #374151;
border-left: 1px solid #e5e7eb;
border-right: 1px solid #e5e7eb;
border-bottom: 1px solid #e5e7eb;
}
#header .actions #notifications-dropdown .header button, #header .actions #notifications-dropdown .footer button, #header .actions #messages-dropdown .footer button, 
#header .actions #notifications-dropdown .header .button, #header .actions #notifications-dropdown .footer .button, #header .actions #messages-dropdown .footer .button {
padding: 0;
display: flex;
align-items: center;
color: #374151;
font-size: 0.875rem;
line-height: 1.25rem;
font-weight: 400;
text-transform: none;
white-space: nowrap;
border-color: transparent;
background-color: transparent;
box-shadow: none;
}
#header .actions #notifications-dropdown .footer button.clear, #header .actions #messages-dropdown .footer button.mark {
margin-left: auto;
}
#header .actions #notifications-dropdown .footer button:hover, #header .actions #messages-dropdown .footer button:hover, 
#header .actions #notifications-dropdown .footer .button:hover, #header .actions #messages-dropdown .footer .button:hover {
color: #111827;
}
#header .actions #notifications-dropdown .header button i, #header .actions #notifications-dropdown .footer button i, #header .actions #messages-dropdown .footer button i, 
#header .actions #notifications-dropdown .header .button i, #header .actions #notifications-dropdown .footer .button i, #header .actions #messages-dropdown .footer .button i {
flex: none;
margin-right: .25rem;
width: 1rem;
height: 1rem;
display: inline-block;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: inline-block;
}
#header .actions #notifications-dropdown .header button.configure i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23374151'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z' /%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E");
}
#header .actions #notifications-dropdown .footer button.clear i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23374151'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' /%3E%3C/svg%3E");
}
#header .actions #messages-dropdown .footer button.inbox i, #header .actions #messages-dropdown .footer .inbox i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23374151'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4' /%3E%3C/svg%3E");
}
#header .actions #messages-dropdown .footer button.mark i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23374151'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
}

/*** Links ***/
.links {
margin: 0;
padding: 0;
list-style: none;
list-style-type: none;
display: inline-flex;
white-space: nowrap;
}
.links li {
margin: 0 0 0 .5rem;
padding: 0;
justify-content: center;
align-items: center;
display: inline-flex;
list-style: none;
list-style-type: none;
}
.links li:first-child {
margin-left: 0;
}
.links a {
display: inline-flex;
}
.links a svg {
width: 1rem;
height: 1rem;
display: inline-block;
}
.links .button {
flex: none;
border-color: #d1d5db;
}
.links .button svg {
width: 1.5rem;
height: 1.5rem;
}








/*** Tabs & Panes ***/
.tabs {
margin: 0;
padding: 0;
display: block;
/*display: flex;*/
/*
display: inline-flex;
*/
width: 100%;
list-style: none;
list-style-type: none;
/*border-bottom: 1px solid #9ca3af;*/
}
@media (min-width: 768px) {
.tabs {
display: flex;
border-bottom: 1px solid #9ca3af;
}
}
.tabs > li {
margin: 0;
padding: 0;
list-style: none;
list-style-type: none;
border-bottom: 1px solid #9ca3af;
}
/*
.tabs > li.active {
border-bottom: 0px solid;
}
*/
.tabs > li.active {
border-bottom: 1px solid #10b981;
}
@media (min-width: 768px) {
.tabs > li {
border-bottom: 0px solid;
}
}
.tabs > li > a, .tabs > li > span {
margin: 0;
padding: .75rem 2rem;
font-size: 1.1rem;
display: flex;
cursor: pointer;
position: relative;
color: #111827;
}
.tabs > li > a strong, .tabs > li > span strong {
margin-left: .25rem;
}
.tabs > li.active > a, .tabs > li.active > span {
color: #000000;
}
.tabs > li.active > a:before, .tabs > li.active > span:before {
transition-duration: 0.4s;
transition-timing-function: ease;
transition-delay: initial;
transition-property: background-color;
background-color: #10b981;
left: 0%;
content: '';
display: block;
width: 4px;
top: 0px;
position: absolute;
height: 100%;
}
@media (min-width: 768px) {
.tabs > li > a, .tabs > li > span {
padding: .5rem 2rem;
font-size: 1rem;
}
.tabs > li.active > a:before, .tabs > li.active > span:before {
display: none;
}
.tabs > li.active > a:after, .tabs > li.active > span:after {
transition-duration: 0.4s;
transition-timing-function: ease;
transition-delay: initial;
transition-property: background-color;
background-color: #10b981;
bottom: 0%;
content: '';
display: block;
height: 3px;
left: 0px;
position: absolute;
width: 100%;
}
}
.panes {
margin: 2rem 0 2rem;
padding: 0;
}
@media (min-width: 768px) {
.panes {
margin: 1rem 0 2rem;
}
}
.panes > .pane {
margin: 0;
padding: 0;
display: none;
}
.panes > .active {
display: block;
}











/*** Dropdown ***/
.dropdown-content {
display: block;
list-style: none;
list-style-type: none;
margin: .5rem 0 0 0;
padding: .5rem 0;
position: absolute;
right: 0;
transform-origin: top right;
width: 14rem;
border-radius: .25rem;
background-color: #ffffff;
box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
z-index: 20;
max-height: 24rem;
overflow-y: auto;
}
.form-group .dropdown-content, .tree-select .dropdown-content, .autocomplete .dropdown-content {
transform-origin: top left;
width: 100%;
border: 1px solid #9ca3af;
}
.dropdown-content li, .dropdown-content div {
margin: 0;
padding: 0;
display: block;
list-style: none;
list-style-type: none;
}
#profile-dropdown.dropdown-content li:last-child {
border-top: 1px solid #f3f4f6;
}
.dropdown-content li > a, .dropdown-content li > span, .dropdown-content div > span {
display: flex;
align-items: center;
padding: .5rem 1rem;
text-decoration: none;
font-size: 0.875rem;
line-height: 1.25rem;
background-color: #ffffff;
color: #374151;
cursor: pointer;
}
.dropdown-content li.level-0 > a, .dropdown-content li.level-0 > span, .dropdown-content div > span.level-0 {
padding-left: 1rem;
}
.dropdown-content li.level-1 > a, .dropdown-content li.level-1 > span, .dropdown-content div > span.level-1 {
padding-left: 2rem;
}
.dropdown-content li.level-2 > a, .dropdown-content li.level-2 > span, .dropdown-content div > span.level-2 {
padding-left: 3rem;
}
.dropdown-content li.level-3 > a, .dropdown-content li.level-3 > span, .dropdown-content div > span.level-3 {
padding-left: 4rem;
}
.dropdown-content li.level-4 > a, .dropdown-content li.level-4 > span, .dropdown-content div > span.level-4 {
padding-left: 5rem;
}
.dropdown-content li > a:hover, .dropdown-content li > span:hover, .dropdown-content div > span:hover, .dropdown-content li.active > a, .dropdown-content li.active > span {
background-color: #f3f4f6;
}
.dropdown-content li > a svg, .dropdown-content li > span svg {
margin-right: .5rem;
width: 1rem;
height: 1rem;
}
.dropdown-content li i {
margin-right: .5rem;
width: 1rem;
height: 1rem;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
.dropdown-content li.account i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23374151'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
}
.dropdown-content li.messenger i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23374151'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z' /%3E%3C/svg%3E");
}
.dropdown-content li.logout i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23374151'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1' /%3E%3C/svg%3E");
}
.dropdown-content li.delete i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23374151'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' /%3E%3C/svg%3E");
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23374151' d='M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z' /%3E%3C/svg%3E");*/
}







/*** Spinner ***/
progress:indeterminate {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border: 0;
background: none;
bottom: 0;
left: 0;
right: 0;
top: 0;
z-index: 10;
width: 100%;
height: 100%;
display: block;
position: absolute;
background-size: 3rem 3rem;
background-position: center center;
background-repeat: no-repeat;
}
.buttons progress:indeterminate, .form-group progress:indeterminate, .form-action progress:indeterminate {
background-size: 1.5rem 1.5rem;
}
progress:indeterminate {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38 38' stroke='%23aaaaaa'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18' /%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite' /%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
button progress:indeterminate {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38 38' stroke='%23ffffff'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18' /%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite' /%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
progress:indeterminate::-moz-progress-bar {
background: none; /* display:none doesn’t work, don’t ask me why */
}
progress:indeterminate::-webkit-progress-bar {
display: none;
}
progress:indeterminate::-ms-fill {
animation-name: none; /* Explicitly must be `animation-name: none` */
}









/*** Main ***/
#main {
margin:  6rem 0 2.5rem;
padding: 0 1rem;
}
h1#title {
margin: 0 0 1.5rem;
padding: 0;
text-align: center;
/* display: flex; */
display: block;
vertical-align: middle;
align-items: center;
justify-content: center;
}
h1#title a {
color: #111827;
}
h1#title a.button {
color: #ffffff;
}
h1#title span {
flex: none;
margin-left: .5rem;
padding: .25rem .5rem;
font-size: .875rem;
line-height: 1rem;
vertical-align: middle;
border-radius: .25rem;
display: inline-block;
background-color: #e5e7eb;
}
h1#title span:hover {
background: #f3f4f6;
}
h1#title span.tooltip {
margin-left: 0;
padding: 0;
border-radius: 0;
background-color: transparent;
border-bottom: 1px dotted #111827;
cursor: help;
position: relative;
white-space: nowrap;
font-weight: 500;
}
/*
h1#title button {
flex: none;
margin-left: .5rem;
padding: .25rem .5rem;
font-size: .75rem;
line-height: 1rem;
}
*/
/*
#main form {
margin: 3rem auto;
}
*/
#main form {
margin: 0 auto 1.5rem;
}













/*** Breadcrumbs ***/
.breadcrumbs {
margin-bottom: 1rem;
text-align: center;
font-size: .875rem;
line-height: 1.25rem;
}
.breadcrumbs ul, .breadcrumbs ul li {
padding: 0;
list-style: none;
list-style-type: none;
display: inline;
}
.breadcrumbs li:after {
margin: auto 0.5rem;
display: inline-block;
content: '>';
color: #9ca3af;
}
.breadcrumbs li:last-child:after {
content: none;
}
.breadcrumbs li a {
color: #111827;
}


















/*** Notification ***/
.notification-container {
font-size: .875rem;
line-height: 1.25rem;
position: fixed;
z-index: 999999;
top: 80px;
right: 20px;
transition: transform .6s ease-in-out;
animation: toast-in-right .7s;
-webkit-animation: toast-in-right .7s;
}
.toast {
position: relative;
pointer-events: auto;
box-shadow: 0 0 10px #999999;
opacity: .9;
color: #ffffff;
width: 18rem;
padding: .75rem;
margin-bottom: .75rem;
cursor: pointer;
border-radius: .25rem;
display: flex;
-webkit-animation: toast-in-right .7s;
animation: toast-in-right .7s;
transition: .3s ease;
right: 0;
}
@media (min-width: 640px) {
.toast {
width: 20rem;
}
}
.toast:hover {
box-shadow: 0 0 12px #fff;
opacity: 1;
}
.toast p, .toast ul {
margin: 0 0 0 .5rem;
display: block;
flex: 1 1 auto;
}
.toast i {
flex: none;
display: block;
width: 1rem;
height: 1rem;
vertical-align: top;
margin-top: .125rem;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
.toast.success {
background-color: #5cb85c;
}
.toast.success i {
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg%3E%3Crect fill='none' height='402' width='582' y='-1' x='-1' /%3E%3C/g%3E%3Cg%3E%3Cpath fill='%23ffffff' d='m16,0c-8.836,0 -16,7.164 -16,16s7.164,16 16,16s16,-7.164 16,-16s-7.164,-16 -16,-16zm-2.48,23.383l-7.362,-7.363l2.828,-2.828l4.533,4.535l9.617,-9.617l2.828,2.828l-12.444,12.445z' /%3E%3C/g%3E%3C/svg%3E");*/
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z' /%3E%3C/svg%3E");
}
.toast.warning {
background-color: #ed8936;
}
.toast.warning i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z' /%3E%3C/svg%3E");
}
.toast.info {
background-color: #4299e1;
}
.toast.info i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z' /%3E%3C/svg%3E");
}
.toast.error {
background-color: #d9534f;
}
.toast.error i {
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg%3E%3Crect fill='none' id='canvas_background' height='402' width='582' y='-1' x='-1' /%3E%3C/g%3E%3Cg%3E%3Ccircle fill='%23ffffff' r='16' cy='16' cx='16' /%3E%3Cpath fill='%23d72828' d='m14.5,25l3,0l0,-3l-3,0l0,3zm0,-19l0,13l3,0l0,-13l-3,0z' /%3E%3C/g%3E%3C/svg%3E");*/
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z' /%3E%3C/svg%3E");
}
.toast button {
margin-left: .5rem;
margin-right: 0;
outline: none;
border: none;
cursor: pointer;
border: 0;
flex: none;
vertical-align: top;
background: none;
box-shadow: none;
width: 1rem;
height: 1rem;
margin-top: .125rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z' /%3E%3C/svg%3E");
background-size: .625rem .625rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
.toast ul, .toast ul li {
padding: 0;
list-style: none;
list-style-type: none;
}
.toast ul li {
margin: 0;
}
@keyframes toast-in-right {
from {
transform: translateX(100%);   
}
to {
transform: translateX(0);
}
}
@keyframes toast-in-left {
from {
transform: translateX(-100%);
}
to {
transform: translateX(0);
}
}









/*** Username ***/
.username {
white-space: nowrap;
/* display: flex; */
display: inline-flex;
align-items: center;
}
.username a {
color: #111827;
text-decoration: none;
}
.username i {
margin-right: .25rem;
display: inline-block;
width: 1rem;
height: 1rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23111827'%3E%3Cpath fill-rule='evenodd' d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z' clip-rule='evenodd' /%3E%3C/svg%3E");
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
.username b {
margin-left: .25rem;
display: inline-block;
width: 1rem;
height: 1rem;
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%2310b981'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' /%3E%3C/svg%3E");*/
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%2310b981'%3E%3Cpath d='M13.474 2.80108C14.2729 1.85822 15.7271 1.85822 16.526 2.80108L17.4886 3.9373C17.9785 4.51548 18.753 4.76715 19.4892 4.58733L20.9358 4.23394C22.1363 3.94069 23.3128 4.79547 23.4049 6.0278L23.5158 7.51286C23.5723 8.26854 24.051 8.92742 24.7522 9.21463L26.1303 9.77906C27.2739 10.2474 27.7233 11.6305 27.0734 12.6816L26.2903 13.9482C25.8918 14.5928 25.8918 15.4072 26.2903 16.0518L27.0734 17.3184C27.7233 18.3695 27.2739 19.7526 26.1303 20.2209L24.7522 20.7854C24.051 21.0726 23.5723 21.7315 23.5158 22.4871L23.4049 23.9722C23.3128 25.2045 22.1363 26.0593 20.9358 25.7661L19.4892 25.4127C18.753 25.2328 17.9785 25.4845 17.4886 26.0627L16.526 27.1989C15.7271 28.1418 14.2729 28.1418 13.474 27.1989L12.5114 26.0627C12.0215 25.4845 11.247 25.2328 10.5108 25.4127L9.06418 25.7661C7.86371 26.0593 6.6872 25.2045 6.59513 23.9722L6.48419 22.4871C6.42773 21.7315 5.94903 21.0726 5.24777 20.7854L3.86969 20.2209C2.72612 19.7526 2.27673 18.3695 2.9266 17.3184L3.70973 16.0518C4.10824 15.4072 4.10824 14.5928 3.70973 13.9482L2.9266 12.6816C2.27673 11.6305 2.72612 10.2474 3.86969 9.77906L5.24777 9.21463C5.94903 8.92742 6.42773 8.26854 6.48419 7.51286L6.59513 6.0278C6.6872 4.79547 7.86371 3.94069 9.06418 4.23394L10.5108 4.58733C11.247 4.76715 12.0215 4.51548 12.5114 3.9373L13.474 2.80108Z' /%3E%3Cpath d='M13.5 17.625L10.875 15L10 15.875L13.5 19.375L21 11.875L20.125 11L13.5 17.625Z' fill='white' stroke='white' /%3E%3C/svg%3E");
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
.username span {
margin-left: .25rem;
font-size: 0.875rem;
color: #4b5563;
}
.username span s {
color: #10b981;
text-decoration: none;
font-weight: 600;
}


.new {
display: inline-block;
padding: 0 .5rem;
background-color: #fde68a;
/*background-color: #fef9c3;*/
color: #000000 !important;
font-size: .875rem;
line-height: 1.25rem;
vertical-align: middle;
border-radius: .25rem;
}
.username .new {
padding: .25rem .5rem .25rem;
font-size: .75rem;
line-height: 1rem;
/*background-color: #fde68a;*/
}
.new a {
color: #000000 !important;
}



/*** Tags ***/
ul.tags {
margin: 0;
padding: 0;
display: inline-block;
list-style: none;
list-style-type: none;
}
ul.tags li {
margin: .5rem .5rem .5rem 0;
padding: 0;
display: inline-block;
list-style: none;
list-style-type: none;
}
.tag {
margin: 0;
padding: .25rem 1rem;
font-size: .875rem;
line-height: 1.25rem;
border-radius: 9999px;
background-color: #e5e7eb;
white-space: nowrap;
text-decoration: none;
}
.tag:hover {
background-color: #d1d5db;
}



/*** Pagination ***/
/*
.pagination {
color: #374151;
}
.pagination p {
display: block;
font-size: 0.875rem;
line-height: 1.25rem;
}
@media (min-width: 640px) {
.pagination {
display: flex;
flex: 1 1 0%;
justify-content: space-between;
align-items: center;
}
.pagination p {
margin: 0;
}
}
*/
.pagination {
color: #374151;
display: flex;
flex: 1 1 0%;
justify-content: space-between;
align-items: center;
}
.pagination p {
margin: 0;
display: block;
font-size: 0.875rem;
line-height: 1.25rem;
}


/*** Pager ***/
ul.pager {
margin: 0;
padding: 0;
list-style: none;
list-style-type: none;
position: relative;
display: inline-flex;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
border-radius: .25rem;
}
ul.pager li {
margin: 0;
padding: 0;
list-style: none;
list-style-type: none;
display: none;
}
ul.pager li.previous, ul.pager li.next {
display: inline-block;
}
@media (min-width: 640px) {
ul.pager li {
display: inline-block;
}
}
ul.pager li span {
margin-left: -1px;
padding: .5rem 1rem;
color: #374151;
position: relative;
font-size: 0.875rem;
line-height: 1.25rem;
align-items: center;
display: inline-flex;
border-width: 1px;
border-style: solid;
border-color: #d1d5db;
background-color: #ffffff;
cursor: default;
border-width: 1px;
min-height: 38px;
}
ul.pager li.current span {
color: #9ca3af;
}
ul.pager li a {
margin-left: -1px;
padding: .5rem 1rem;
transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 150ms;
color: #374151;
position: relative;
font-size: 0.875rem;
line-height: 1.25rem;
align-items: center;
display: inline-flex;
border-width: 1px;
border-style: solid;
border-color: #d1d5db;
background-color: #ffffff;
min-height: 38px;
}
ul.pager li.previous a, ul.pager li.next a, 
ul.pager li.previous span, ul.pager li.next span {
width: 1rem;
/*height: 100%;*/
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: block;
}
ul.pager li.previous a {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236b7280' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' /%3E%3C/svg%3E");
}
ul.pager li.next a {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236b7280' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' /%3E%3C/svg%3E");
}
ul.pager li.previous a:hover, ul.pager li.previous span {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%239ca3af' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' /%3E%3C/svg%3E");
}
ul.pager li.next a:hover, ul.pager li.next span {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%239ca3af' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' /%3E%3C/svg%3E");
}
ul.pager li:first-child a, ul.pager li:first-child span {
border-top-left-radius: .25rem;
border-bottom-left-radius: .25rem;
}
ul.pager li:last-child a, ul.pager li:last-child span {
border-top-right-radius: .25rem;
border-bottom-right-radius: .25rem;
}
ul.pager li a:hover {
color: #6b7280;
}
ul.pager li a:active {
color: #374151;
background-color: #f3f4f6;
}



/*** Video ***/
div.video {
width: 100%;
position: relative;
overflow: hidden;
max-width: 100%;
height: 0;
padding-top: 56.25%;
}
div.video iframe {
bottom: 0;
left: 0;
right: 0;
top: 0;
width: 100%;
height: 100%;
position: absolute;
}







article.auction, article.lot, article.business, article.club, article.coupon, article.event, article.fake, article.article, article.user {
margin-bottom: 1.5rem;
padding: .75rem 1.5rem 1.5rem;
width: 100%;
position: relative;
border-radius: .25rem;
border: 1px solid #d1d5db;
color: #111827;
text-align: left;
background: #ffffff;
}
/*
.view-block article.event, .view-block article.article {
display: flex;
flex-direction: column;
margin-bottom: 0;
}
*/
/*
article.event, article.fake, article.article {
padding: 0;
}
*/
article.coupon {
border: 1px dashed #d1d5db;
}
article.auction.featured, article.lot.featured, article.business.featured, article.club.featured, article.coupon.featured, article.event.featured, article.fake.featured, article.article.featured {
background: #fffbeb;
border: 1px solid #fcd34d;
}
article.auction.pending, article.lot.pending, article.business.pending, article.club.pending, article.coupon.pending, article.event.pending, article.fake.pending, article.article.pending, article.user.pending {
background: #f9f9f9;
color: #666666;
}
article.auction.deleted, article.lot.deleted, article.business.deleted, article.club.deleted, article.coupon.deleted, article.event.deleted, article.fake.deleted, article.article.deleted, article.user.deleted {
background: #fff0f0;
border: 1px solid #e89090;
}
.view-grid article.auction, .view-grid article.lot, .view-grid article.lot.featured, .view-grid article.lot.pending, .view-grid article.lot.deleted, .view-grid article.event, .view-grid article.event.featured, .view-grid article.event.pending, .view-grid article.event.deleted, .view-grid article.fake, .view-grid article.fake.featured, .view-grid article.fake.pending, .view-grid article.fake.deleted, .view-grid article.article, .view-grid article.article.featured, .view-grid article.article.pending, .view-grid article.article.deleted,
.view-block article.auction, .view-block article.auction.featured, .view-block article.auction.pending, .view-block article.auction.deleted, .view-block article.lot, .view-block article.lot.featured, .view-block article.lot.pending, .view-block article.lot.deleted, .view-block article.event, .view-block article.event.featured, .view-block article.event.pending, .view-block article.event.deleted, .view-block article.fake, .view-block article.fake.featured, .view-block article.fake.pending, .view-block article.fake.deleted, .view-block article.article, .view-block article.article.featured, .view-block article.article.pending, .view-block article.article.deleted {
border: 0px solid;
padding: 0;
}
/*
.view-block article.lot {
border: 1px solid #ff0000;
}
*/
/*
article.business figure, article.club figure {
padding: .25rem 0;
width: 8rem;
height: auto;
flex: none;
}
article.event figure, article.fake figure, article.article figure {
padding: 0;
width: 100%;
height: 12rem;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
}
*/
article.auction figure, article.lot figure, article.business figure, article.club figure, article.event figure, article.fake figure, article.article figure, article.user figure {
padding: .25rem 0;
width: 8rem;
height: auto;
flex: none;
position: relative;
}
article.auction figure {
width: 15rem;
}
article.lot figure {
width: 12rem;
}
.view-grid article.event figure, .view-grid article.article figure, 
.view-block article.event figure, .view-block article.article figure {
padding: 0;
width: 100%;
height: 12rem;
background: #cbd5e1;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
overflow: hidden;
}
.view-grid article.lot figure, .view-grid article.fake figure, 
.view-block article.lot figure, .view-block article.fake figure {
margin: 0 auto;
padding: 0 0 100%;
width: 100%;
height: auto;
/*background: #cbd5e1;*/
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
overflow: hidden;

border: 1px solid #cbd5e1;
border-bottom: 0px solid;
}
.view-block article.lot figure {
/*background: transparent;*/
border: 0px solid;
}
article.lot figure > div, article.fake figure > div {
position: relative;
}
.view-grid article.lot figure > div, .view-grid article.fake figure > div, 
.view-block article.lot figure > div, .view-block article.fake figure > div {
top: 0;
left: 0;
width: 100%;
height: 100%;
position: absolute;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
}
/*
.view-block article.lot figure > div {
width: auto;
border-width: 1px 1px 0 1px;
border-style: solid;
border-color: #d1d5db;
}
*/

.view-grid article.lot figure div > a, .view-grid article.fake figure div a, 
.view-block article.lot figure div > a, .view-block article.fake figure div a {
width: 100%;
height: 100%;
display: flex;
vertical-align: middle;
justify-content: center;
align-items: center;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
}
.view-block article.lot figure div > a {
width: auto;
border: 1px solid #d1d5db;
background-position: center center;
background-size: 100%;
background-repeat: no-repeat;
}
/*
.view-grid article.lot figure div > a, .view-grid article.fake figure div a, 
.view-block article.lot figure div > a, .view-block article.fake figure div a {
width: auto;
height: 100%;
display: flex;
vertical-align: middle;
justify-content: center;
align-items: center;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
border: 1px solid #d1d5db;
background-position: center center;
background-size: 100%;
background-repeat: no-repeat;
}
*/
article.auction figure img, article.lot figure img, article.business figure img, article.club figure img, article.event figure img, article.fake figure img, article.article figure img, article.user figure img {
width: 100%;
height: auto;
}
article.user figure img {
border-radius: 9999px;
display: block;
}
.view-grid article.auction figure img, .view-grid article.event figure img, .view-grid article.article figure img, 
.view-block article.auction figure img, .view-block article.event figure img, .view-block article.article figure img {
height: 12rem;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
}
.view-grid article.lot figure img, .view-grid article.fake figure img, 
.view-block article.lot figure img, .view-block article.fake figure img {
margin: auto;
width: auto;
height: auto;
/*border: 1px solid transparent;*/
display: inline-block;
vertical-align: middle;
position: relative;
max-width: 100%;
max-height: 100%;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
}
/*
article.business .content, article.club .content, article.coupon .content {
width: 100%;
line-height: 1.5;
}
article.event .content, article.fake .content, article.article .content {
padding: .75rem 1.5rem 1.5rem;
line-height: 1.5;
border-bottom-left-radius: .25rem;
border-bottom-right-radius: .25rem;
}
*/
article.auction .content, article.lot .content, article.business .content, article.club .content, article.coupon .content, article.event .content, article.fake .content, article.article .content, article.user .content {
width: 100%;
line-height: 1.5;
}
.view-grid article.auction .content, .view-grid article.lot .content, .view-grid article.event .content, .view-grid article.fake .content, .view-grid article.article .content, 
.view-block article.auction .content, .view-block article.lot .content, .view-block article.event .content, .view-block article.fake .content, .view-block article.article .content {
width: auto;
/*padding: .75rem 1.5rem 1.5rem;*/
padding: .75rem 1rem 1rem;
border-bottom-left-radius: .25rem;
border-bottom-right-radius: .25rem;
border-width: 0 1px 1px 1px;
border-style: solid;
border-color: #d1d5db;
}
/*
.view-block article.event .content, .view-block article.article .content {
flex-grow: 1;
}
*/
.view-grid article.auction.featured .content, .view-grid article.lot.featured .content, .view-grid article.event.featured .content, .view-grid article.fake.featured .content, .view-grid article.article.featured .content, 
.view-block article.auction.featured .content, .view-block article.lot.featured .content, .view-block article.event.featured .content, .view-block article.fake.featured .content, .view-block article.article.featured .content {
border-color: #fcd34d;
} 

/*
article.business h2, article.club h2 {
margin-bottom: .5rem;
font-size: 1.25rem;
line-height: 1.75rem;
font-weight: 700;
display: flex;
align-items: flex-start;
}
article.event h2, article.fake h2, article.article h2 {
margin-bottom: .5rem;
font-size: 1.25rem;
line-height: 1.75rem;
font-weight: 700;
display: block;
}
*/
article.auction h2, article.lot h2, article.business h2, article.club h2, article.event h2, article.fake h2, article.article h2, article.user h2 {
margin-bottom: .5rem;
font-size: 1.25rem;
line-height: 1.75rem;
/*font-weight: 700;*/
display: flex;
align-items: flex-start;
}
article.lot h2 {
display: block;
}
.view-grid article.auction h2, .view-grid article.lot h2, .view-grid article.event h2, .view-grid article.fake h2, .view-grid article.article h2, 
.view-block article.auction h2, .view-block article.lot h2, .view-block article.event h2, .view-block article.fake h2, .view-block article.article h2 {
display: block;
}
article.coupon h2 {
margin-bottom: .5rem;
font-size: 1.25rem;
line-height: 1.75rem;
/*font-weight: 500;*/
text-align: center;
display: block;
}
/*
article.business h2 a, article.club h2 a {
flex-grow: 1;
margin-right: auto;
color: #111827;
}
article.event h2 a, article.fake h2 a, article.article h2 a {
color: #111827;
}
*/
article.lot h2 a {
color: #111827;
}
article.auction h2 a, article.business h2 a, article.club h2 a, article.event h2 a, article.fake h2 a, article.article h2 a, article.user h2 .username {
flex-grow: 1;
margin-right: auto;
color: #111827;
}
.view-grid article.auction h2 a, .view-grid article.lot h2 a, .view-grid article.event h2 a, .view-grid article.fake h2 a, .view-grid article.article h2 a, 
.view-block article.auction h2 a, .view-block article.lot h2 a, .view-block article.event h2 a, .view-block article.fake h2 a, .view-block article.article h2 a {
flex-grow: 0;
margin-right: 0;
}
article.auction h2 span, article.lot h2 span, article.business h2 span, article.club h2 span, article.event h2 span, article.article h2 span, article.user h2 > span {
display: flex;
align-items: center;
margin: .125rem 0 0 .5rem;
padding: .125rem .375rem;
font-size: .75rem;
line-height: 1rem;
font-weight: 600;
flex: none;
background: transparent;
transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
white-space: nowrap;
}
article.auction h2 span, article.lot h2 span, article.event h2 span, article.article h2 span, article.user h2 > span {
display: inline-flex;
margin: .25rem 0 0 .5rem;
}
article.business h2 span, article.club h2 span, article.event h2 span {
color: #10b981;
border: 1px solid #10b981;
border-radius: 9999px;
text-transform: uppercase;
}
article.auction h2 span, article.lot h2 span, article.article h2 span, article.user h2 > span {
background: #e5e7eb;
border-radius: .25rem;
}
article.business h2 span:hover, article.club h2 span:hover, article.event h2 span:hover {
color: #047857;
border: 1px solid #ecfdf5;
background: #ecfdf5;
}
article.auction h2 span:hover, article.lot h2 span:hover, article.article h2 span:hover, article.user h2 > span:hover {
background: #f3f4f6;
}
/*
.view-grid article.lot h2 span.lot-no {
position: absolute;
top: 1px;
left: 1px;
margin: 0;
padding: .25rem .5rem .25rem;
font-size: .75rem;
line-height: 1rem;
display: flex;
border-radius: 0;
border-top-left-radius: .25rem;
border-bottom-right-radius: .25rem;
background-color: #e5e7eb;
color: #6b7280;
}
.view-grid article.lot h2 span.lot-no:hover {
background-color: #e5e7eb;
color: #1f2937;
}
*/
article.lot .meta {
position: absolute;
top: 1px;
right: 1px;
padding: .25rem .5rem .25rem;
font-size: .75rem;
line-height: 1rem;
display: flex;
border-radius: .25rem;
background-color: #ffffff;
background-color: rgba(255, 255, 255, 0.75);
color: #6b7280;
}
.view-block article.lot .meta {
top: 1px;
right: 1px;
}
article.lot .meta:hover {
color: #1f2937;
}
article.lot .meta span {
display: flex;
align-items: center;
padding: 0 0 0 .5rem;
}
article.lot .meta span:first-child {
padding: 0;
}
article.lot .meta span i {
margin-right: .25rem;
width: 1rem;
height: 1rem;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
article.lot .meta span.views i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%236b7280'%3E%3Cpath d='M10 12a2 2 0 100-4 2 2 0 000 4z' /%3E%3Cpath fill-rule='evenodd' d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
article.lot .meta:hover span.views i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%231f2937'%3E%3Cpath d='M10 12a2 2 0 100-4 2 2 0 000 4z' /%3E%3Cpath fill-rule='evenodd' d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
article.lot .meta span.followers i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%236b7280'%3E%3Cpath d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' /%3E%3C/svg%3E");
}
article.lot .meta:hover span.followers i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%231f2937'%3E%3Cpath d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' /%3E%3C/svg%3E");
}
article.lot .meta span.bids i, article.lot .meta span.offers i {
width: .75rem;
background-size: .75rem 1rem;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236b7280' d='M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z' /%3E%3C/svg%3E");
}
article.lot .meta:hover span.bids i, article.lot .meta:hover span.offers i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231f2937' d='M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z' /%3E%3C/svg%3E");
}
article.lot .lot-no {
position: absolute;
top: 1px;
left: 1px;
margin: 0;
padding: .25rem .5rem .25rem;
font-size: .75rem;
line-height: 1rem;
display: flex;
border-radius: 0;
border-top-left-radius: .25rem;
border-bottom-right-radius: .25rem;
background-color: #e5e7eb;
color: #6b7280;
}
article.lot .lot-no:hover {
background-color: #e5e7eb;
color: #1f2937;
}
article.lot figure .new {
position: absolute;
bottom: 0;
left: 0;
padding: .25rem .5rem .25rem;
font-size: .75rem;
line-height: 1rem;
display: flex;
border-radius: .25rem;
background-color: #fde68a;
color: #000000;
}
.view-block article.lot figure .new {
bottom: 1px;
left: 1px;
}
/*
article.lot .username .new {
position: static;
display: inline-flex;
}
*/

article.event .meta {
margin-bottom: .25rem;
display: flex;
align-items: flex-start;
}
article.event .meta div {
flex-grow: 1;
margin-right: auto;
font-size: .875rem;
line-height: 1.25rem;
color: #4b5563;
}
article.event .meta span {
display: flex;
align-items: center;
margin: 0 0 0 .5rem;
padding: .125rem .375rem;
font-size: .75rem;
line-height: 1rem;
font-weight: 600;
flex: none;
background: transparent;
transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
white-space: nowrap;
background: #e5e7eb;
border-radius: .25rem;
}
article.coupon .offer {
margin-bottom: .5rem;
color: rgb(245,158,11);
font-size: 1.875rem;
line-height: 2.25rem;
font-weight: 700;
text-align: center;
}
article.coupon .expires, article.coupon .description, article.coupon .code, article.coupon .disclaimer {
margin: 0;
padding: .5rem 0;
font-size: .875rem;
line-height: 1.25rem;
text-align: center;
}
article.coupon .expires, article.coupon .disclaimer {
font-style: italic;
color: rgba(156,163,175);
}
article.coupon .code {
display: flex;
justify-content: center;
align-items: center;
}
article.coupon .code span {
text-transform: uppercase;
margin-right: .5rem;
display: inline-block;
}
article.coupon .code strong {
padding: .25rem .75rem;
color: rgba(5,150,105);
font-size: 1.25rem;
line-height: 1.75rem;
font-weight: 700;
display: inline-block;
border:  1px solid rgba(229,231,235);
border-radius: .25rem;
}
article.lot .price {
float: right;
margin: 0 0 1rem 1rem;
padding: 0;
text-align: right;
font-size: 1.25rem;
}
.view-grid article.lot .price, .view-block article.lot .price {
float: none;
font-size: 1rem;
margin: 1rem 0 1rem 0;
}
article.lot .price strong {
margin: 0;
padding: 0 .5rem;
letter-spacing: .1em;
color: #000000;
font-weight: 600;
border-radius: .25rem;
background-color: #d1fae5;
display: inline-block;
}
article.lot .summary {
margin-bottom: 1rem;
}
article.lot .date, article.lot .username {
clear: both;
display: flex;
font-size: .875rem;
line-height: 1.25rem;
margin-bottom: .5rem;
}
article.lot .date {
color: #4b5563;
}
article.lot .status {
margin: 0 0 1rem;
background-color: #e5e7eb;
border-radius: .25rem;
display: inline-block;
flex: none;
font-size: .75rem;
line-height: 1rem;
padding: .25rem .5rem;
text-transform: uppercase;
vertical-align: middle;
text-align: center;
}
.view-grid article.lot .status {
display: block;
border: 1px solid #e5e7eb;
margin: 0;
padding: .5rem .75rem;
line-height: 1.5rem;
}
article.lot .status strong {
color: #111827;
}
.view-grid article.lot .actions button, .view-grid article.lot .actions .button {
display: block;
}
article.auction .categories, article.business .categories, article.club .categories, article.event .categories {
margin-bottom: .25rem;
}
article.business .address, article.club .address, article.event .address, article.user .address {
margin: 0;
display: block;
}
article.auction .categories, article.business .categories, article.business .address, article.club .categories, article.club .address, article.event .categories, article.event .address, article.user .address {
display: flex;
color: #9ca3af;
font-size: 0.875rem;
line-height: 1.25rem;
}
article.auction .categories ul, article.business .categories ul, article.club .categories ul, article.event .categories ul {
margin: 0 0 0 .5rem;
padding: 0;
display: inline;
list-style: none;
list-style-type: none;
}
article.auction .categories ul li, article.business .categories ul li, article.club .categories ul li, article.event .categories ul li {
margin: 0;
padding: 0;
list-style: none;
list-style-type: none;
display: inline;
}
article.auction .categories ul li:after, article.business .categories ul li:after, article.club .categories ul li:after, article.event .categories ul li:after {
content: ', ';
}
article.auction .categories ul li:last-child:after, article.business .categories ul li:last-child:after, article.club .categories ul li:last-child:after, article.event .categories ul li:last-child:after {
content: '';
}
article.auction h2 span i, article.business h2 span i, article.club h2 span i, article.event h2 span i, article.auction .categories i, article.business .categories i, article.business .address i, article.club .categories i, article.club .address i, article.event .categories i, article.event .address i, article.user .address i {
flex: none;
width: 1rem;
height: 1.1rem;
background-size: .825rem .825rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: inline-block;
}
article.business .address a, article.club .address a, article.event .address a, 
article.business .address > span, article.club .address > span, article.event .address > span {
margin: 0 0 0 .5rem;
color: #9ca3af;
}
article.business h2 span i, article.club h2 span i, article.event h2 span i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2310b981'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z' /%3E%3C/svg%3E");
}
article.business h2 span:hover i, article.club h2 span:hover i, article.event h2 span:hover i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23047857'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z' /%3E%3C/svg%3E");
}
article.auction .categories i, article.business .categories i, article.club .categories i, article.event .categories i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239ca3af'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10' /%3E%3C/svg%3E");
}
article.business .address i, article.club .address i, article.event .address i, article.user .address i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239ca3af'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z' /%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M15 11a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E");
}
article.business .address .distance, article.club .address .distance, article.event .address .distance {
white-space: nowrap;
color: #111827;
}
article.auction .summary, article.business .summary, article.club .summary, article.event .summary, article.article .summary {
margin-top: 1rem;
margin-bottom: 1rem;
}
article.auction .date {
font-size: .875rem;
line-height: 1.25rem;
color: #4b5563;
}
article.auction .countdown {
padding: .5rem 0 1rem;
text-align: right;
font-size: 1.25rem;
line-height: 1.75rem;
}
article.auction .countdown .timer > strong small {
margin-left: .235rem;
}
article.auction .countdown .timer > strong {
font-weight: normal;
margin-right: .375rem;
}
@media (min-width: 768px) {
article.auction .countdown {
text-align: center;	
}
article.auction .countdown .timer > strong {
display: block;
margin-right: 0;
}
article.auction .countdown .timer > strong span {
font-size: 2rem;
font-weight: 500;
}
}













@media (min-width: 768px) {
/*
article.business, article.club, article.coupon {
display: flex;
max-width: 100%;
}
*/
article.auction, article.lot, article.business, article.club, article.event, article.fake, article.article, article.user {
display: flex;
max-width: 100%;
}
.view-grid {
display: grid;
grid-template-columns: repeat(2, minmax(0, 1fr));
gap: 1rem;
}
.view-grid article.auction, .view-grid article.lot, .view-grid article.event, .view-grid article.fake, .view-grid article.article, 
.view-block article.auction, .view-block article.lot, .view-block article.event, .view-block article.fake, .view-block article.article {
display: flex;
flex-direction: column;
}
.view-block article.auction, .view-block article.lot, .view-block article.event, .view-block article.fake, .view-block article.article {
margin-bottom: 0;
}
.view-block article.lot {
height: 100%;
}
/*
article.business figure, article.club figure {
margin-right: 1.5rem;
}
*/
article.auction figure, article.lot figure, article.business figure, article.club figure, article.event figure, article.fake figure, article.article figure, article.user figure {
margin-right: 1.5rem;
}
.view-grid article.auction figure, .view-grid article.lot figure, .view-grid article.event figure, .view-grid article.fake figure, .view-grid article.article figure, 
.view-block article.auction figure, .view-block article.lot figure, .view-block article.event figure, .view-block article.fake figure, .view-block article.article figure {
margin-right: 0;
}
/*
article.business .content, article.club .content, article.coupon .content {
margin-right: 0;
margin-left: 0;
}
*/
article.auction .content, article.lot .content, article.business .content, article.club .content, article.coupon .content, article.event .content, article.fake .content, article.article .content, article.user .content {
margin-right: 0;
margin-left: 0;
}
.view-grid article.auction .content, .view-grid article.lot .content, .view-grid article.event .content, .view-grid article.fake .content, .view-grid article.article .content, 
.view-block article.auction .content, .view-block article.lot .content, .view-block article.event .content, .view-block article.fake .content, .view-block article.article .content {
flex-grow: 1;
}
.view-block article.lot .content {
position: relative;
padding-bottom: 3.5rem;
}
.view-block article.lot .price {
margin-top: 0;
bottom: .5rem;
position: absolute;
right: 1rem;
}
}
@media (min-width: 1024px) {
.view-grid {
grid-template-columns: repeat(3, minmax(0, 1fr));
}
article.auction .content > div {
display: flex;
align-items: flex-start;
width: 100%;
}
article.auction .content > div > div {
flex-grow: 1;
width: auto;
}
article.auction .content > div > div:last-child {
/*margin: 0 0 0 1.5rem;*/
margin: 0 0 0 2.5rem;
flex-grow: 0;
flex-shrink: 0;
/*width: 10rem;*/
display: block;
}
}
@media (min-width: 1280px) {
.view-grid.wide {
grid-template-columns: repeat(4, minmax(0, 1fr));
}
}








article.thread {
margin: 0;
padding: 0;
display: block;
width: 100%;
}
article.thread > a {
margin: 0;
padding: 1rem;
display: flex;
width: 100%;
align-items: flex-start;
color: #374151;
}
article.thread.active > a {
background-color: #f3f4f6;
}
article.thread > a:hover {
background-color: #f3f4f6;
}
article.thread figure {
padding: 0;
flex: none;
}
article.thread figure img {
width: 3rem;
height: 3rem;
vertical-align: middle;
border-radius: 9999px;
background-color: #e5e7eb;
}
article.thread > a > div {
margin-left: 1rem;
width: 100%;
}
article.thread div h4 {
margin: 0;
font-size: 1rem;
font-weight: 600;
display: flex;
width: 100%;
align-items: flex-start;
}
article.thread div h4 > strong, article.thread div h4 > span {
flex-grow: 1;
width: 100%;
font-weight: 600;
}
article.thread.unread div h4 > strong {
font-weight: 800;
}
article.thread div h4 > small {
margin: .125rem 0 0 .5rem;
padding: 0 .25rem;
flex: none;
background: #4b5563;
display: block;
border-radius: .25rem;
color: #ffffff;
font-size: 0.75rem;
line-height: 1rem;
white-space: nowrap;
}
article.thread div h4 > i {
width: 1rem;
height: 1.25rem;
margin-left: .5rem;
flex: none;
background-size: 1rem 1rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: block;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 512'%3E%3Cpath fill='%23d1d5db' d='M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z' /%3E%3C/svg%3E");
}
article.thread div h4 > i:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 512'%3E%3Cpath fill='%234b5563' d='M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z' /%3E%3C/svg%3E");
}
article.thread div p {
margin: 0.25rem 0 0;
}
article.thread div p.deleted {
color: #a1a7b0;
font-style: italic;
}
article.thread div p:last-child {
color: #6b7280;
font-size: 0.75rem;
line-height: 1rem;
}






article.notification {
margin: 0;
padding: 0;
display: block;
width: 100%;
}
article.notification > a, article.notification > div {
margin: 0;
padding: 1rem;
display: flex;
width: 100%;
align-items: flex-start;
color: #374151;
}
article.notification.active > a, article.notification.active > div {
background-color: #f3f4f6;
}
article.notification > a:hover, article.notification > div:hover {
background-color: #f3f4f6;
}
article.notification figure {
padding: 0;
flex: none;
}
article.notification figure img {
width: 2rem;
height: 2rem;
vertical-align: middle;
border-radius: 9999px;
background-color: #e5e7eb;
}
article.notification > a > div, article.notification > div > div {
margin-left: 1rem;
width: 100%;
}
article.notification div h4 {
margin: 0;
font-size: .875rem;
font-weight: 600;
}
article.notification div h4 > strong, article.notification div h4 > span {
flex-grow: 1;
width: 100%;
font-weight: 600;
}
article.notification.unread div h4 > strong {
font-weight: 800;
}
article.notification div p {
margin: 0.25rem 0 0;
}
article.notification div p:last-child {
color: #6b7280;
font-size: 0.75rem;
line-height: 1rem;
}









article.post, article.comment {
margin-bottom: 1.5rem;
padding: 0 0 1.5rem;
display: block;
}
@media (min-width: 768px) {
article.post, article.comment {
display: grid;
grid-template-columns: repeat(4,minmax(0,1fr));
}
}
.view-list article.post, .view-list article.comment {
border-bottom: 1px solid #e5e7eb;
}
.view-list article.post:last-child, .view-list article.comment:last-child {
border-bottom: 0px solid;
}
article.post .content, article.comment .content {
position: relative;
}
@media (min-width: 768px) {
article.post .content, article.comment .content {
grid-column: span 3/span 3;
}
}
article.post .info, article.comment .info {
margin: 0 0 1rem;
position: relative;
}
article.post .info {
padding: 0 0 0 6rem;
min-height: 5rem;
}
article.post .info figure {
position: absolute;
top: 0;
left: 0;
}
@media (min-width: 768px) {
article.post .info {
margin: 0;
padding: 0;
}
article.comment .info {
margin: 0;
padding: 1rem 0 0;
}
article.post .info figure {
position: static;
top: none;
left: none;
}
}
article.post .info h3 {
margin-bottom: .5rem;
}
article.comment .info .date {
font-size: .875rem;
line-height: 1.25rem;
color: #4b5563;
}
article.post .info figure a, article.post .info figure img {
width: 5rem;
height: 5rem;
border-radius: 9999px;
display: block;
}
@media (min-width: 768px) {
article.post .info figure a, article.post .info figure img {
width: 8rem;
height: 8rem;
}
}
article.post .info button.reputation {
margin-bottom: .5rem;
padding: .25rem .5rem;
color: #ffffff;
background-color: #9ca3af;
border: 1px solid #9ca3af;
display: inline-flex;
align-items: center;
font-size: .75rem;
line-height: 1rem;
}
article.post .info button.reputation:hover {
background-color: #374151;
border: 1px solid #374151;
}
article.post .info button.reputation.positive {
background-color: #10b981;
border: 1px solid #10b981;
}
article.post .info button.reputation.positive:hover {
background-color: #047857;
border: 1px solid #047857;
}
article.post .info button.reputation i { 
flex: none;
margin-right: .5rem;
width: .75rem;
height: .75rem;
display: inline-block;
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: inline-block;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23ffffff'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
article.post .info .total, article.post .info .user-since, article.post .info .rating {
margin-bottom: .25rem;
color: #4b5563;
font-size: .875rem;
line-height: 1.25rem;
}
article.post .info .user-since, article.post .info .rating {
display: none;
}
@media (min-width: 768px) {
article.post .info .user-since, article.post .info .rating {
display: block;
}
}
article.post .info .level {
margin: 0 .5rem .25rem 0;
display: inline-block;
color: #4b5563;
}
@media (min-width: 768px) {
article.post .info .level {
margin: 0 0 .25rem 0;
display: block;
}
}
article.post .info .rating s {
color: #10b981;
text-decoration: none;
font-weight: 600;
}
article.post .content ul.meta {
margin: 0 0 .5rem;
padding: 0;
list-style: none;
list-style-type: none;
display: flex;
font-size: .875rem;
line-height: 1.25rem;
}
article.post .content ul.meta li {
list-style: none;
list-style-type: none;
display: inline-block;
flex: none;
}
article.post .content ul.meta li.report, article.post .content ul.meta li.report span {
margin-left: .75rem;
color: #d1d5db;
}
article.post .content ul.meta li.report span {
cursor: pointer;
}
article.post .content ul.meta li.report:hover, article.post .content ul.meta li.report span:hover {
color: #4b5563;
}
article.post .content ul.meta li.report:before {
content: '• '; /* &bull; */
}
article.post .content ul.meta li.link {
margin-left: auto;
font-size: 1rem;
}
article.post .content .body, article.comment .content .body {
margin: 0;
padding: 1.25rem 1rem;
color: #374151;
border-radius: .25rem;
background: #f3f4f6;
}
/*
article.post .content .body {
margin-bottom: 1rem;
}
*/
article.post .content .body {
min-height: 250px;
}
article.post.pending .content .body, article.comment.pending .content .body, 
article.post.deleted .content .body, article.comment.deleted .content .body {
color: #cccccc;
}
article.post .content .with-spinner, article.comment .content .with-spinner {
position: relative;
}
@media (min-width: 768px) {
article.post .content .body, article.comment .content .body {
padding: 1.5rem;
}
}
article.post .content .body.positive {
background-color: #ecfdf5;
border: 1px solid #16a34a;
}
article.post .content .body.negative {
background-color: #fff1f2;
border: 1px solid #dc2626;
}
article.post .content .actions, article.comment .content .actions {
margin-top: 1rem;
display: flex;
align-items: center;
}
article.post .content .actions button, article.post .content .actions .button, article.comment .content .actions button, article.comment .content .actions .button {
padding: .25rem .5rem;
font-size: .75rem;
line-height: 1rem;
flex: none;
}
article.post .content .actions .multi-quote {
background-color: #9ca3af;
border: 1px solid #9ca3af;
width: 1.5rem;
height: 1.5rem;
display: inline-block;
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
display: inline-block;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23ffffff' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 6v6m0 0v6m0-6h6m-6 0H6' /%3E%3C/svg%3E");
}
article.post .content .actions .multi-quote.copied { 
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23ffffff' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 13l4 4L19 7' /%3E%3C/svg%3E");
}
article.post .content .actions .multi-quote:hover {
background-color: #374151;
border: 1px solid #374151;
}
article.post .content .actions .reply, article.post .content .actions .edit, article.post .content .actions .publish, article.post .content .actions .hide, article.post .content .actions .delete, 
article.comment .content .actions .reply, article.comment .content .actions .edit, article.comment .content .actions .publish, article.comment .content .actions .hide, article.comment .content .actions .delete {
font-weight: 600;
font-size: .875rem;
text-transform: none;
color: #6b7280;
}
article.post .content .actions .reply:hover, article.post .content .actions .edit:hover, article.post .content .actions .publish:hover, article.post .content .actions .hide:hover, article.post .content .actions .delete:hover, 
article.comment .content .actions .reply:hover, article.comment .content .actions .edit:hover, article.comment .content .actions .publish:hover, article.comment .content .actions .hide:hover, article.comment .content .actions .delete:hover {
color: #374151;
}
article.post .content .actions .feedback {
margin-left: auto;
display: inline-flex;
align-items: center;
}
article.post .content .actions .feedback .rating { 
margin: 0;
background-color: #ffffff;
color: #9ca3af;
border: 1px solid #9ca3af;
display: inline-flex;
align-items: center;
cursor: default;
}
article.post .content .actions .feedback .rating i { 
flex: none;
margin-right: .5rem;
width: .75rem;
height: .75rem;
display: inline-block;
background-size: .75rem .75rem;
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
display: inline-block;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%239ca3af'%3E%3Cpath fill-rule='evenodd' d='M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
article.post .content .actions .feedback.positive .rating { 
background-color: #ecfdf5;
color: #059669;
border-color: #059669;
}
article.post .content .actions .feedback.positive .rating i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23059669'%3E%3Cpath fill-rule='evenodd' d='M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
article.post .content .actions .feedback.positive .rating:hover {
background-color: #d1fae5;
}
article.post .content .actions .feedback.negative .rating { 
background-color: #fef2f2;
color: #dc2626;
border-color: #dc2626;
}
article.post .content .actions .feedback.negative .rating i {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23dc2626'%3E%3Cpath fill-rule='evenodd' d='M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z' clip-rule='evenodd' /%3E%3C/svg%3E");
}
article.post .content .actions .feedback.negative .rating:hover {
background-color: #fee2e2;
}
article.post .content .actions .feedback:not(.disabled):hover .rating {
border-left: 0px solid;
border-right: 0px solid;
border-radius: 0;
cursor: pointer;
}
article.post .content .actions .feedback .like, article.post .content .actions .feedback .dislike {
margin: 0;
background-color: #9ca3af;
color: #ffffff;
border: 1px solid #9ca3af;
border-radius: 0;
display: none;
}
article.post .content .actions .feedback.positive .like, article.post .content .actions .feedback.positive .dislike {
background-color: #059669;
border-color: #059669;
}
article.post .content .actions .feedback.negative .like, article.post .content .actions .feedback.negative .dislike {
background-color: #dc2626;
border-color: #dc2626;
}
article.post .content .actions .feedback .like {
border-top-left-radius: .25rem;
border-bottom-left-radius: .25rem;
}
article.post .content .actions .feedback .dislike {
border-top-right-radius: .25rem;
border-bottom-right-radius: .25rem;
}
article.post .content .actions .feedback .like:hover, article.post .content .actions .feedback .dislike:hover {
background-color: #374151;
border: 1px solid #374151;
}
article.post .content .actions .feedback.positive .like:hover, article.post .content .actions .feedback.positive .dislike:hover {
background-color: #047857;
border-color: #047857;
}
article.post .content .actions .feedback.negative .like:hover, article.post .content .actions .feedback.negative .dislike:hover {
background-color: #be123c;
border-color: #be123c;
}
article.post .content .actions .feedback:not(.disabled):hover .like, article.post .content .actions .feedback:not(.disabled):hover .dislike {
display: inline-block;
}
/*
article.post .content .actions .disabled:hover .like, article.post .content .actions .disabled:hover .dislike {
display: none !important;
}
*/













/*** Footer ***/
#footer {
padding: 4rem 0 0;
background-color: #d1d5db;
color: #111827;
font-size: .875rem;
line-height: 1.25rem;
}
#footer a {
color: #111827;
}
#footer > .container {
padding: 0 1rem;
position: relative;
}
#footer .column-2 {
padding: 1rem 0 0;
}
#footer .column-3 {
margin-top: 1rem;
}
#footer .column-2 ul, #footer .column-3 ul {
margin: 1rem 0 0;
padding: 0;
list-style: none;
list-style-type: none;
}
#footer .column-2 ul li, #footer .column-3 ul li {
margin: 0;
padding: 0 1rem .5rem 0;
list-style: none;
list-style-type: none;
display: inline-block;
color: #94a3b8;
}
#footer .column-2 ul li:last-child, #footer .column-3 ul li:last-child {
padding: 0;
}
@media (min-width: 640px) {
#footer .columns {
padding: 0;
}
#footer .column-1 {
margin: 0;
position: absolute;
width: 160px;
top: 0;
left: 0;
}
#footer .column-2 {
margin: 0 100px 0 180px;
padding: 0 1.5rem;
}
#footer .column-3 {
margin: 0;
position: absolute;
top: 0;
right: 0;
}
#footer .column-3 ul li {
padding: 0 0 .5rem;
display: block;
}
}
@media (min-width: 768px) {
#footer .column-2 {
padding: 0 3rem;
}
}
#footer .column-2 p {
margin: 0 0 1rem;
}
#footer .column-3 ul li img {
margin-right: .25rem;
width: 1rem;
height: auto;
display: inline-block;
}
#footer .copyright {
margin:  4rem 0 0;
padding: 1rem 0;
background-color: #6b7280;
color: #f3f4f6;
}
#footer .copyright .container:after {
clear: both;
content: '';
display: table;
float: none;
}
/*
#footer .copyright svg {
display: inline-block;
width: 1.25rem;
height: 1.25rem;
}
*/
#footer .copyright p {
margin: 0;
padding: 0 1.25rem;
display: block;
text-align: center;
}
#footer .copyright .links {
margin: .5rem 0 0;
padding: 0;
text-align: center;
display: block;
}
#footer .copyright .links a svg {
width: 1.25rem;
height: 1.25rem;
display: inline-block;
}
/*
#footer .copyright ul {
margin: .5rem 0 0;
padding: 0;
list-style: none;
list-style-type: none;
white-space: nowrap;
text-align: center;
}
#footer .copyright li {
margin: 0;
padding: 0 .5rem 0 0;
list-style: none;
list-style-type: none;
display: inline-block;
}
#footer .copyright li:last-child {
padding: 0;
}
*/

@media (min-width: 768px) {
#footer .copyright p {
padding: 0;
float: left;
white-space: nowrap;
}
/*
#footer .copyright ul {
margin-top: 0;
text-align: right;
}
*/
#footer .copyright .links {
margin-top: 0;
text-align: right;
float: right;
display: inline-flex;
}
#footer .copyright:after {
content: '';
display: table;
height: 0;
clear: both;
}
}
#footer .copyright a {
color: #f3f4f6;
}

/*** Link to Top ***/
#link-to-top {
position: fixed;
right: .75rem;
bottom: 2.5rem;
z-index: 40;
}
#link-to-top a {
display: block;
padding: .5rem;
color: #ffffff;
border-radius: .25rem;
background-color: rgba(0,0,0,.5);
}
#link-to-top svg {
width: 1.5rem;
height: 1.5rem;
}

/*** Modals ***/
.modal {
padding: 0 1rem;
outline: 2px solid transparent;
outline-offset: 2px;
overflow-x: hidden;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
z-index: 50;
overflow-y: auto;
position: fixed;
justify-content: center;
align-items: center;
display: flex;
background-color: rgba(0, 0, 0, .5);
}
.modal > div {
width: 100%;
position: relative;
margin: 1.5rem auto;
outline: 2px solid transparent;
outline-offset: 2px;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
border: 0px solid;
border-radius: .25rem;
background-color: #ffffff;
color: rgb(55, 65, 81);
}
@media (min-width: 640px) {
.modal > div {
width: 32rem;
}
}
@media (min-width: 768px) {
.modal > div {
width: 36rem;
}
}
#main .modal form {
margin: 0;
padding: 0;
}
.modal .header {
padding: .75rem 1rem;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
color: rgb(17, 24, 39);
display: flex;
background-color: #f0f0f0;
}
.modal .header h3 {
margin: 0;
line-height: 2rem;
font-size: 1.25rem;
font-weight: 600;
flex-grow: 1;
}
.modal .header button {
margin: .5rem 0 0 .5rem;
outline: none;
cursor: pointer;
border: 0;
flex: none;
vertical-align: top;
background: none;
box-shadow: none;
width: 1rem;
height: 1rem;
background-size: .75rem .75rem;
/*background-size: .625rem .625rem;*/
background-position: center center;
background-repeat: no-repeat;
background-color: transparent;
}
.modal .header button {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' viewBox='0 0 320 512'%3E%3Cpath fill='%239ca3af' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z' /%3E%3C/svg%3E");
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%239ca3af' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /%3E%3C/svg%3E");*/
}
.modal .header button:hover {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' viewBox='0 0 320 512'%3E%3Cpath fill='%23374151' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z' /%3E%3C/svg%3E");
/*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23374151' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /%3E%3C/svg%3E");*/
}
.modal .body {
padding: 1rem;
}
.modal .body fieldset {
margin: 0;
border: 0;
box-shadow: none;
padding: 0;
}
.modal .body fieldset legend {
display: none;
}
.modal .body fieldset .content {
background-color: transparent;
padding: 0;
}
.modal .footer {
padding: 1.5rem 1rem;
text-align: right;
border-bottom-right-radius: .25rem;
border-bottom-left-radius: .25rem;
background-color: rgb(249, 250, 251);
}
.modal .footer .with-spinner {
position: relative;
}
.modal .footer progress:indeterminate {
background-size: 1.5rem 1.5rem;
}
.modal .footer button, .modal .footer .button {
margin-left: .5rem;
}
.modal .footer button:first-child, .modal .footer .button:first-child {
margin-left: 0;
}


/*** CKEditor ***/
.ck.ck-reset, .ck.ck-reset_all, .ck.ck-reset_all * {
box-shadow: none;
text-transform: none;
}
.ck.ck-editor {
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.ck.ck-editor__editable_inline {
padding: .5rem 1rem !important;
min-height: 200px;
}
.ck.ck-toolbar {
border: 1px solid #9ca3af !important;
border-bottom: 0px solid !important;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
border: 1px solid #047857 !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
border-color: #9ca3af !important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
border-radius: .25rem !important;
border-bottom-left-radius: 0 !important;
border-bottom-right-radius: 0 !important;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
border-radius: .25rem !important;
border-top-left-radius: 0 !important;
border-top-right-radius: 0 !important;
}
.ck-content figcaption, article.post .body figcaption {
font-size: .875rem;
background: #cccccc;
padding: .25rem .5rem;
}
.ck-content blockquote, article.post .body blockquote {
padding: .5rem 1.5rem;
margin: 0 0 1rem 0;
background: #eeeeee;
font-style: initial !important;
border-left: 5px solid #ccc;
}
.ck-content blockquote p:last-child, article.post .body blockquote p:last-child {
margin-bottom: 0;
}
.ck-content .image, article.post .body .image {
clear: both;
display: table;
margin: 0 0 1rem !important;
min-width: auto !important;
text-align: left !important;
}
.ck-content .image img, article.post .body .image img {
display: block;
margin: 0 auto;
max-width: 100%;
min-width: 100%;
height: auto;
}

/*** Light Gallery ***/
.lg-next:hover:not(.disabled), .lg-prev:hover:not(.disabled) {
background-color: transparent;
border: 0px solid;
box-shadow: none;
}


/*** Tooltips ***/
.tooltip {
position: relative;
}
.tooltip .tip {
position: absolute;
border-radius: .25rem;
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4.21875px 7px -1.0625px, rgba(0, 0, 0, 0.055) 0px 2.0625px 3px -0.703125px;
transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
left: 50%;
transform: translateX(-50%);
padding: .5rem .75rem;
color: #ffffff;
background: #000000;
font-size: 14px;
font-style: italic;
font-weight: normal;
text-transform: none;
width: auto;
text-align: left;
max-width: 250px;
line-height: 1;
height: auto;
z-index: 10;
white-space: normal;
/* white-space: nowrap; */
}
.tooltip .tip::before {
content: " ";
left: 50%;
border: solid transparent;
height: 0;
width: 0;
position: absolute;
pointer-events: none;
border-width: 6px;
margin-left: -6px;
}
.tooltip .tip.top {
/*top: -30px;*/
bottom: 1.5rem;
}
.tooltip .tip.top::before {
top: 100%;
border-top-color: #000000;
}
.tooltip .tip.right {
left: calc(100% + .75rem);
top: 50%;
transform: translateX(0) translateY(-50%);
text-align: left;
}
.tooltip .tip.right::before {
left: -6px;
top: 50%;
transform: translateX(0) translateY(-50%);
border-right-color: #000000;
}
.tooltip .tip.bottom {
/*bottom: -30px;*/
top: calc(100% + 0.75rem);
}
.tooltip .tip.bottom::before {
bottom: 100%;
border-bottom-color: #000000;
}
.tooltip .tip.left {
left: auto;
right: calc(100% + .75rem);
top: 50%;
transform: translateX(0) translateY(-50%);
}
.tooltip .tip.left::before {
left: auto;
right: -12px;
top: 50%;
transform: translateX(0) translateY(-50%);
border-left-color: #000000;
}
